import * as React from 'react';
import { CssBaseline, Container, Grid, Box } from '@material-ui/core';

import Header from 'components/Header/Header';
import { useStyles } from './styles';
import Copyright from 'components/Global/Copyright';
import Drawer from 'components/Drawer/Drawer';
import { ILayout } from 'types/Header/Layout';

/**
 * Global layout
 * @param {React.ReactNode} children
 * @param {boolean} drawerVisible enable the drawer for header or not
 */
const LayoutHome: React.FC<ILayout> = ({ children, drawerVisible, maxWidth, slider }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header drawerVisible={drawerVisible} />
      {drawerVisible && <Drawer slider={slider} />}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={maxWidth ? maxWidth : 'lg'} className={classes.container}>
          <Grid container spacing={3}>
            {children}
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
};

export default LayoutHome;
