/* tslint:disable: jsx-no-lambda */
import * as React from 'react';
import {
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';

import { useStyles } from './styles';
import InputField from 'components/Global/InputLabel/InputLabel';
import { BootstrapInput } from 'components/Global/PickerSelect/PickerSelect';

import { IVehicleInfoProps, INumberFormatMoneyValuePros } from 'types/CarsQuotation/IVehicleInfo';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { REQUIRED_FIELD } from 'constants/notifyConstants';
import { LICENSE_PLATE_VALIDATION } from 'constants/index';

const NumberFormatMoneyValue = (props: INumberFormatMoneyValuePros) => {
  const onChangeValue = (e: NumberFormatValues) => {
    return props.onChange(e.floatValue);
  };

  return (
    <NumberFormat
      customInput={InputField}
      required={props.required ? props.required : false}
      thousandSeparator={true}
      prefix={'$'}
      onValueChange={onChangeValue}
      value={props.value}
      label={props.labelContent}
      id={props.idComponent}
      disabled={props.disabled}
    />
  );
};

const VehicleInfo: React.FC<IVehicleInfoProps> = ({
  control,
  serviceItems,
  loadCitiesOptions,
  handleInputCitiesChange,
  useVehicleItems,
  errors,
  onValidateLicensePlate,
  checkboxAccesories,
  onChangeFasecolda,
  disabledFasecoldaField,
  onModeloChange,
  disableSecuredValue,
}) => {
  // styles
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} sm={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend" className={classes.label}>
            Tipo de Vehículo:
          </FormLabel>
          <Controller
            as={
              <RadioGroup aria-label="gender" row name="gender1">
                <FormControlLabel value="nuevo" control={<Radio color="primary" />} label="0 KM" />
                <FormControlLabel value="usado" control={<Radio color="primary" />} label="Usado" />
              </RadioGroup>
            }
            name="tVehicle"
            control={control}
            rules={{
              required: REQUIRED_FIELD,
            }}
          />
          {errors.tVehicle && errors.tVehicle.type === 'required' && (
            <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
              {errors.tVehicle.message}
            </Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl className={classes.margin}>
          <Typography className={classes.label}>Servicio</Typography>
          <Controller
            control={control}
            name="service"
            as={
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                input={<BootstrapInput />}>
                {serviceItems.map((menuItem: any, index: any) => (
                  <MenuItem key={index} value={menuItem.value}>
                    {menuItem.label}
                  </MenuItem>
                ))}
              </Select>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl className={classes.margin}>
          <Typography className={classes.label}>Uso del Vehículo</Typography>
          <Controller
            as={
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                input={<BootstrapInput />}>
                {useVehicleItems.map((menuItem: any, index: any) => (
                  <MenuItem key={index} value={menuItem.value}>
                    {menuItem.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
            name="useVehicle"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl className={classes.margin}>
          <Typography className={classes.label}>Ciudad de Circulación</Typography>
          <Controller
            as={
              <AsyncSelect
                cacheOptions
                loadOptions={loadCitiesOptions}
                defaultOptions
                onInputChange={handleInputCitiesChange}
                placeholder="Seleccionar..."
              />
            }
            control={control}
            name="cityCirculation"
            rules={{
              required: REQUIRED_FIELD,
            }}
          />
          {errors.cityCirculation && errors.cityCirculation.type === 'required' && (
            <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
              {errors.cityCirculation.message}
            </Typography>
          )}
        </FormControl>
        <FormControl className={classes.margin}>
          <Typography className={classes.label}>Ciudad opcional</Typography>
          <Controller
            as={AsyncSelect}
            control={control}
            name="optionalCity"
            cacheOptions
            loadOptions={loadCitiesOptions}
            defaultOptions
            onInputChange={handleInputCitiesChange}
            placeholder="Seleccionar..."
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          render={({ onChange, value }) => (
            <FormControlLabel
              control={
                <Checkbox
                  name="checkedB"
                  color="primary"
                  value={value}
                  onChange={(e) => onChange(e.target.checked)}
                />
              }
              label="¿Desea Ingresar el Valor de los Accesorios?"
            />
          )}
          control={control}
          name="checkAccesories"
        />
        <Controller
          render={(props) => <NumberFormatMoneyValue {...props} disabled={!checkboxAccesories} />}
          control={control}
          name="valueAccesories"
          id="accesories"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          render={(props) => (
            <InputField
              label="Placa"
              id="plate"
              required
              onChange={(e) => props.onChange(e)}
              onBlur={(e) => onValidateLicensePlate(e, props)}
              value={props.value}
            />
          )}
          control={control}
          name="licenseVehicle"
          rules={{
            required: REQUIRED_FIELD,
            pattern: {
              value: LICENSE_PLATE_VALIDATION,
              message: 'Placa invalida. Ej: ABC123',
            },
          }}
        />
        {errors.licenseVehicle && errors.licenseVehicle.type === 'pattern' && (
          <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
            {errors.licenseVehicle.message}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          render={(props) => (
            <InputField
              label="Código Fasecolda"
              id="fasecoldaCode"
              required
              type="text"
              value={props.value}
              onChange={(e) => props.onChange(e)}
              onBlur={(e) => onChangeFasecolda(e, props)}
              disabled={disabledFasecoldaField}
            />
          )}
          control={control}
          name="codeFasecolda"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          render={(props) => (
            <InputField
              label="Modelo (Año)"
              id="modelo"
              required
              type="number"
              disabled={disabledFasecoldaField}
              value={props.value}
              onChange={(e) => props.onChange(e)}
              onBlur={(e) => onModeloChange(e, props)}
            />
          )}
          control={control}
          name="modelo"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          render={({ value }) => (
            <InputField label="Marca" id="brand" type="text" disabled={true} value={value} />
          )}
          control={control}
          name="brand"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          render={({ value }) => (
            <InputField label="Ref1" id="ref1" type="text" disabled value={value} />
          )}
          control={control}
          name="ref1"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          control={control}
          name="ref2"
          render={({ value }) => (
            <InputField label="Ref2" id="ref2" type="text" disabled value={value} />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          control={control}
          name="ref3"
          render={({ value }) => (
            <InputField label="Ref3" id="ref3" type="text" disabled value={value} />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          render={(props) => (
            <NumberFormatMoneyValue
              labelContent="Valor Asegurado"
              idComponent="securedValue"
              {...props}
              disabled={disableSecuredValue}
            />
          )}
          control={control}
          name="valueInsured"
          rules={{
            required: REQUIRED_FIELD,
          }}
        />
        {errors.valueInsured && errors.valueInsured.type === 'required' && (
          <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
            {errors.valueInsured.message}
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default VehicleInfo;
