import * as React from 'react';
import { Home as HomeIcon, Description, PeopleAlt, CloudUpload } from '@material-ui/icons';
import Home from 'pages/Home/Home';
import SummaryOpportunities from 'pages/SummaryOpportunities/SummaryOpportunities';
import { IStructureNavigation } from 'types/Navigation/NavigationStructure';
import CarsQuotation from 'pages/CarsQuotation/CarsQuotation';
import QuotationResume from 'pages/QuotationResume/QuotationResume';
import UserControl from 'pages/Admin/UserControl/UserControl';
import UploadFasecolda from 'pages/Admin/UploadFasecolda/UploadFasecolda';

export const naturalStructureNavigation: IStructureNavigation = {
  slider: [
    {
      id: 0,
      label: 'Inicio',
      link: '/app/inicio',
      icon: <HomeIcon />,
    },
    {
      id: 1,
      label: 'Resumen Oportunidades',
      link: '/app/resumen-oportunidades',
      icon: <Description />,
    },
  ],
  route: [
    {
      path: '/app/inicio',
      component: Home,
    },
    {
      path: '/app/resumen-oportunidades',
      component: SummaryOpportunities,
    },
    {
      path: '/app/cotizador/autos',
      component: CarsQuotation,
    },
    {
      path: '/app/cotizador/autos/resumen/:id',
      component: QuotationResume,
    },
  ],
};

export const adminStructureNavigation: IStructureNavigation = {
  slider: [
    {
      id: 0,
      label: 'Inicio',
      link: '/app/inicio',
      icon: <HomeIcon />,
    },
    {
      id: 1,
      label: 'Resumen Oportunidades',
      link: '/app/resumen-oportunidades',
      icon: <Description />,
    },
    {
      id: 2,
      label: 'Control de usuarios',
      link: '/app/control-usuarios',
      icon: <PeopleAlt />,
    },
    {
      id: 3,
      label: 'Actualizar Fasecolda',
      link: '/app/actualizar-fasecolda',
      icon: <CloudUpload />,
    },
  ],
  route: [
    {
      path: '/app/control-usuarios',
      component: UserControl,
    },
    {
      path: '/app/inicio',
      component: Home,
    },
    {
      path: '/app/resumen-oportunidades',
      component: SummaryOpportunities,
    },
    {
      path: '/app/cotizador/autos',
      component: CarsQuotation,
    },
    {
      path: '/app/cotizador/autos/resumen/:id',
      component: QuotationResume,
    },
    {
      path: '/app/actualizar-fasecolda',
      component: UploadFasecolda,
    },
  ],
};
