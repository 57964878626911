import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig } from 'axios';

/**
 * Default header object
 */
const defaultHeader: IHeader = {
  headers: {
    Authorization: null,
    'Content-Type': 'application/json',
  },
};

/**
 * Http request class helper
 */
export class HttpRequest {
  constructor() {
    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  /**
   * Get request
   * @param {string} request - API URL
   */
  public async Get<T>(request: string): Promise<AxiosRequestConfig> {
    const token = `Bearer ${await this.GetToken()}`;
    const headers = {
      headers: {
        ...defaultHeader.headers,
        Authorization: token,
      },
    };

    const response = await axios.get(request, headers);
    // const body = await response.json();

    return response;
  }

  /**
   * Post request
   * @param {string} request - API URL
   * @param {object} object - body request
   */
  public async Post<T>(request: string, object: object): Promise<AxiosRequestConfig> {
    const token = `Bearer ${await this.GetToken()}`;
    const body = object;
    const headers = {
      headers: {
        ...defaultHeader.headers,
        Authorization: token,
      },
    };
    const response = await axios.post(request, body, headers);

    return response;
  }

  public async Put<T>(request: string, body: any, config: any): Promise<AxiosRequestConfig> {
    const response = await axios.put(request, body, config);

    return response;
  }

  /**
   * Get current token from cognito
   */
  private async GetToken(): Promise<any> {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().getJwtToken();
  }
}
