import * as React from 'react';
import {
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

import { useStyles } from './styles';

const ApplicantDetails: React.FC<IApplicantDetailsProps> = ({ tableData }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        Datos del Solicitante
      </Typography>
      <TableContainer>
        <Table aria-label="caption table">
          <TableBody>
            {tableData.map((row: any) => (
              <TableRow key={row.title}>
                <TableCell component="th" scope="row" className={classes.rowTitle}>
                  {row.title}
                </TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ApplicantDetails;
