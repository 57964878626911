import * as React from 'react';
import { Typography, TextField } from '@material-ui/core';

import { useStyles } from './styles';

/**
 * Simple input with title label
 * @param {string} label - label of input
 * @param {boolean} disabled
 * @param {string} placeholder
 * @param {string} id - id and name definition
 * @param {string} autoComplete - [This prop helps users to fill forms faster](https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill)
 * @param {boolean} required - Required field default false
 */
const InputLabel: React.FC<ILabelInputProps> = React.memo(
  ({ label, disabled, placeholder, id, autoComplete, required, type, value, onChange, onBlur }) => {
    const classes = useStyles();

    return (
      <>
        <Typography className={classes.label}>{label}</Typography>
        <TextField
          required={required ? required : false}
          id={id}
          name={id}
          fullWidth
          autoComplete={autoComplete}
          variant="outlined"
          size="small"
          disabled={disabled}
          placeholder={placeholder}
          type={type ? type : 'text'}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      </>
    );
  }
);

export default InputLabel;
