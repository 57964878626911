import { Auth } from 'aws-amplify';
import swal from 'sweetalert';

import { baseError } from 'constants/notifyConstants';
import {
  IForgotPassword,
  ILoginService,
  INewPassword,
  ISendRecovery,
  ISignOut,
} from 'types/auth/CognitoServices';

/**
 * Login with amplify
 * @param {ILoginService} args - arguments for validation
 */
export const loginService = async (args: ILoginService) => {
  args.setIsLoading(true);

  if (!!args.email && !!args.password) {
    try {
      const user = await Auth.signIn(args.email, args.password);
      const roleType = user.attributes['custom:role'];

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        args.setChallengeName(true);
        args.setIsLoading(false);
      } else {
        args.setChallengeName(false);
        args.setIsLoading(false);

        localStorage.setItem('id_token', user.Session);
        localStorage.setItem('id_email', args.email);
        localStorage.setItem('role', roleType);

        args.dispatch({
          type: 'LOGIN_SUCCESS',
          email: args.email,
          token: user.Session,
        });

        args.roleDispatch({
          type: 'INFO_SUCCESS',
          role: roleType,
        });

        args.history.push('/');
      }
    } catch (err) {
      args.setIsLoading(false);
      args.setPasswordValue('');

      swal('Oops!', err.message, 'error');
    }
  } else {
    args.setIsLoading(false);
    args.setPasswordValue('');

    swal('Oops!', baseError, 'error');
  }
};

/**
 * Change password when the admin creates the account
 * @param {INewPassword} args - params
 */
export const newPasswordRequired = async (args: INewPassword) => {
  args.setIsLoading(true);
  try {
    const user = await Auth.signIn(args.email, args.password);
    const roleType = user.attributes['custom:role'];

    await Auth.completeNewPassword(user, args.newPassword, {
      email: args.email,
    });

    args.setIsLoading(false);

    localStorage.setItem('id_token', user.Session);
    localStorage.setItem('id_email', args.email);
    localStorage.setItem('role', roleType);

    args.dispatch({
      type: 'LOGIN_SUCCESS',
      email: args.email,
      token: user.Session,
    });

    args.roleDispatch({
      type: 'INFO_SUCCESS',
      role: roleType,
    });

    args.history.push('/');
  } catch (err) {
    args.setIsLoading(false);

    swal('Oops!', err.message, 'error');
  }
};

/**
 * Sign out
 * @param {ISignOut} args
 */
export const signOut = async (args: ISignOut) => {
  localStorage.removeItem('id_token');
  localStorage.removeItem('id_email');
  localStorage.removeItem('role');

  await Auth.signOut();
  args.userDispatch({ type: 'SIGN_OUT_SUCCESS' });
  args.infoUserDispatch({ type: 'SIGN_OUT_SUCCESS' });

  args.history.push('/app/iniciar-sesion');
};

/**
 * Send verification code
 * @param {ISendRecovery} args
 */
export const sendPasswordRecovery = async (args: ISendRecovery) => {
  args.setIsSendingCode(true);
  try {
    await Auth.forgotPassword(args.email);
    args.setIsSendingCode(false);
    args.setCodeSent(true);
    swal('Completado', 'Por favor revisa tu correo electrónico', 'success');
  } catch (err) {
    args.setIsSendingCode(false);
    swal('Oops!', err.message, 'error');
  }
};

export const forgotPassword = async (args: IForgotPassword) => {
  args.setIsConfirming(true);
  try {
    await Auth.forgotPasswordSubmit(args.email, args.code, args.password);
    args.setConfirmed(true);
    args.history.push('/app/iniciar-sesion');
  } catch (err) {
    swal('Oops!', err.message, 'error');
    args.setIsConfirming(false);
  }
};
