import * as dotenv from 'dotenv';

dotenv.config();

/**
 * API URL
 */
export const API = process.env.REACT_APP_API;

/**
 * Node environment
 */
export const NODE_ENV = process.env.NODE_ENV;

/**
 * Base url with stage
 */
// export const baseUrl = `${API}${NODE_ENV === 'development' ? 'dev' : 'prod'}`;
export const baseUrl = `${API}`;

/**
 * Quote
 */
export const quotationUrl = {
  quotation: '/crearCotizacion',
  fasecoldaCode: '/numeroFasecolda/',
  quoteResume: '/obtenerCotizacion/',
  stateInsurer: '/verificarCotizacion/',
  validateQuotation: '/validarPlaca/',
  refFasecolda: '/obtenerGuiaFasecolda/',
  resetBot: '/reiniciar/',
};

/**
 * Upload fasecolda files
 */
export const fasecoldaUrl = {
  urlUpload: '/urlUpload',
};

/**
 * Admin users control
 */
export const userControl = {
  cognitoUsers: '/cognitoUsers',
  cotizacionesUsers: '/cotizaciones',
};
