export const defaultValues: ISignNewUserFormValues = {
  name: '',
  lastName: '',
  email: '',
  password: '',
  role: '',
  branchOffice: '',
};

export const roleType = [
  { value: 'admin', label: 'Administrador' },
  { value: 'asesor', label: 'Asesor' },
];

export const branchOfficeTypes = [{ value: 'bogota', label: 'Bogotá' }];
