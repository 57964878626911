import React, { FC, useState } from 'react';
import LockOutlinedIcon from '@material-ui/icons/VpnKey';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { CircularProgress, Avatar, Button, TextField, Box, Typography } from '@material-ui/core';

import Layout from 'components/Auth/Layout';
import Copyright from 'components/Global/Copyright';
import { useStyles } from './styles';
import { INVALID_EMAIL, INVALID_PASSWORD } from 'constants/notifyConstants';
import { IsNotEmptyField, IsValidEmail } from 'utils/utils';
import { forgotPassword, sendPasswordRecovery } from 'services/AuthService';
import { TEventTarget } from 'types';

const RequestCode: FC<IRequestCode> = ({ onPress, setEmailValue, emailValue, isSendingCode }) => {
  const classes = useStyles();

  // handlers
  const onChange = (e: TEventTarget) => setEmailValue(e.target.value);

  React.useEffect(() => {
    document.title = 'Recuperar contraseña';
  }, []);

  return (
    <>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Correo electrónico"
          name="email"
          autoComplete="email"
          autoFocus
          onChange={onChange}
          value={emailValue}
        />
        {isSendingCode ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={onPress}
            disabled={emailValue.length === 0}>
            Enviar confirmación
          </Button>
        )}
        <Box mt={5}>
          <Copyright />
        </Box>
      </form>
    </>
  );
};

const ConfirmationForm: FC<IConfirmationForm> = ({
  setCode,
  code,
  setPasswordValue,
  passwordValue,
  setConfirmPasswordValue,
  confirmPasswordValue,
  isConfirming,
  handleConfirmPassword,
  validation,
}) => {
  // styles
  const classes = useStyles();

  // handlers
  const onChangeCode = (e: TEventTarget) => setCode(e.target.value);
  const onChangeNewPassword = (e: TEventTarget) => setPasswordValue(e.target.value);
  const onChangeConfirmNewPassword = (e: TEventTarget) => setConfirmPasswordValue(e.target.value);

  return (
    <>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="code"
          type="number"
          label="Insertar código"
          name="code"
          autoFocus
          onChange={onChangeCode}
          value={code}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Nueva contraseña"
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={onChangeNewPassword}
          value={passwordValue}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="confirmPassword"
          label="Confirmar nueva contraseña"
          type="password"
          id="confirmPassword"
          autoComplete="current-password"
          onChange={onChangeConfirmNewPassword}
          value={confirmPasswordValue}
        />
        {isConfirming ? (
          <CircularProgress size={26} />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleConfirmPassword}
            disabled={!validation()}>
            Cambiar contraseña
          </Button>
        )}
        <Box mt={5}>
          <Copyright />
        </Box>
      </form>
    </>
  );
};

const RecoverPassword = () => {
  const classes = useStyles();

  // states
  const [code, setCode] = useState<string>('');
  const [emailValue, setEmailValue] = useState<string>('');
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [confirmPasswordValue, setConfirmPasswordValue] = useState<string>('');
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  const [isSendingCode, setIsSendingCode] = useState<boolean>(false);

  // navigation
  const history = useHistory();

  const sendEmailConfirmation = () => {
    if (IsValidEmail(emailValue)) {
      sendPasswordRecovery({ email: emailValue, setIsSendingCode, setCodeSent });
    } else {
      swal('Oops!', INVALID_EMAIL.message, 'error');
    }
  };

  const validation = () =>
    code.length > 0 && passwordValue.length > 0 && passwordValue === confirmPasswordValue;

  const handleConfirmPassword = () => {
    if (IsNotEmptyField(passwordValue)) {
      forgotPassword({
        email: emailValue,
        code,
        password: passwordValue,
        setIsConfirming,
        setConfirmed,
        history,
      });
    } else {
      swal('Oops!', INVALID_PASSWORD.message, 'error');
    }
  };

  return (
    <Layout>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Recuperar contraseña
        </Typography>
        {!codeSent ? (
          <RequestCode
            onPress={sendEmailConfirmation}
            emailValue={emailValue}
            isSendingCode={isSendingCode}
            setEmailValue={setEmailValue}
          />
        ) : (
          !confirmed && (
            <ConfirmationForm
              code={code}
              confirmPasswordValue={confirmPasswordValue}
              handleConfirmPassword={handleConfirmPassword}
              isConfirming={isConfirming}
              passwordValue={passwordValue}
              setCode={setCode}
              setConfirmPasswordValue={setConfirmPasswordValue}
              setPasswordValue={setPasswordValue}
              validation={validation}
            />
          )
        )}
      </div>
    </Layout>
  );
};

export default RecoverPassword;
