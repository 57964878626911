/* eslint-disable import/prefer-default-export */
import moment from 'moment';
import 'moment/locale/es';

type TEmail = string;
type Text = string;
type TDate = string | Date | null | undefined;

/**
 * Email validation regular expression.
 * @param {string} email example@example.com
 */
export const IsValidEmail = (email: TEmail): boolean => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
};

export const milisecondDate = (date: number): string => {
  return moment(date).format("DD MMM YYYY hh:mm a");
}

/**
 * Validates if is there any empty field.
 * @param {string} text
 */
export const IsNotEmptyField = (text: Text): boolean => text !== '';

/**
 * Humanize a date
 * @example one hour ago.
 * @param {string} date
 */
export const formatHumanizeDate = (date: TDate): string => {
  const r1 = +moment(date);
  const r2 = +moment();
  const result = r1 - r2;
  return moment.duration(result).locale('es').humanize();
};

/**
 * Formal date format.
 * @example Sunday, 14 de June de 2020 23:28
 * @param {string} date
 */
export const formatDate = (date: TDate): TDate => {
  if (date !== undefined) {
    return moment(date).locale('es').format('LLLL');
  }
};

/**
 * Formate calendar date
 * @example 02/01/2020
 * @param {string} date
 */
export const formatAsCalendar = (date: TDate): TDate => {
  if (date !== undefined) {
    return moment(date).calendar();
  }
};

/**
 * Create array of objects
 * @param label - title label
 * @param value - result of data
 */
export const createDataList = (title: TContent, value: TContent): IApplicantTableData => {
  return { title, value };
};
