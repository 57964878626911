import * as React from 'react';
import { Grid, Container, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import Card from 'components/Card/Card';
import { useStyles } from './styles';
import { cards } from './resources';

const Home: React.FC = () => {
  // styles
  const classes = useStyles();

  // navigation
  const history = useHistory();

  // handler
  const navigationHandler = (path: INavigationHandlerPath) => {
    history.push(path);
  };

  React.useEffect(() => {
    document.title = 'Inicio';
  }, []);

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      {/* End hero unit */}
      <Typography variant="h3" gutterBottom className={classes.title}>
        Realiza tu cotización
      </Typography>
      <Grid container spacing={4}>
        {cards.map((card: ICardContent) => (
          <Card
            key={card.id}
            title={card.title}
            disabled={card.disabled}
            onPress={navigationHandler}
            path={card.path}
            description={card.description}
            image={card.image}
          />
        ))}
      </Grid>
    </Container>
  );
};

export default Home;
