import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import HomeIcon from '@material-ui/icons/Home';
import ResumeIcon from '@material-ui/icons/Description';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Link } from 'react-router-dom';
import { ISlider } from 'types/Navigation/NavigationStructure';

/**
 * Main List with top of fields
 */
export const mainListItems = (
  <div>
    <ListItem button component={Link} to="/inicio">
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="Inicio" />
    </ListItem>
    <ListItem button component={Link} to="/resumen-oportunidades">
      <ListItemIcon>
        <ResumeIcon />
      </ListItemIcon>
      <ListItemText primary="Resumen de oportunidades" />
    </ListItem>
  </div>
);

/**
 * Secondary list in case that want to divide the drawer list
 */
export const SecondaryListItems = () => (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);

const DrawerList: React.FC<ISlider> = ({ link, label, icon }) => {
  return (
    <div>
      <ListItem button component={Link} to={link}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    </div>
  );
};

export default DrawerList;
