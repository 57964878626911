import { Auth } from 'aws-amplify';
import swal from 'sweetalert';

export const signNewUser: TSignNewUserService = async (
  email,
  password,
  name,
  lastName,
  role,
  branchOffice,
  toggleModal
) => {
  try {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        name: `${name} ${lastName}`,
        'custom:role': role,
        'custom:branchOffice': branchOffice,
        'custom:pwd': password,
      },
    });
    toggleModal();
    swal('Completado', `El usuario ${name} ${lastName} ha sido creado`, 'success');
  } catch (e) {
    swal('Oops!', e.message, 'error');
  }
};
