import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  mainTitle: {
    fontWeight: 'bold',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 16,
    boxSizing: 'border-box',
    width: '100%',
  },
  dropzone: {
    height: 200,
    width: 200,
    backgroundColor: '#fff',
    border: '2px dashed rgb(187, 186, 186)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: 16,
  },
  highlight: {
    backgroundColor: 'rgb(188, 185, 236)',
  },
  fileInput: {
    display: 'none',
  },
  root: {
    minWidth: 275,
    padding: 20,
    marginTop: 20,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  files: {
    marginLeft: 32,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flex: 1,
    overflowY: 'auto',
  },
  rowFile: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 8,
    overflow: 'hidden',
    boxSizing: 'border-box',
  },
  fileName: {
    marginBottom: 8,
  },
}));
