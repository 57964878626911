import React from 'react';
import { Drawer, IconButton, Divider, List } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';

import { useStyles } from './styles';
import DrawerList, { mainListItems } from './DrawerList';
import { useLayoutState, useLayoutDispatch, toggleSidebar } from 'context/LayoutContext';
import { ISlider } from 'types/Navigation/NavigationStructure';

const SideDrawer: React.FC<{ slider?: ISlider[] }> = ({ slider }) => {
  // styles
  const classes = useStyles();

  // contexts
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  /**
   * Close drawer event
   */
  const handleDrawerClose = () => {
    toggleSidebar(layoutDispatch);
  };

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !isSidebarOpened && classes.drawerPaperClose),
      }}
      open={isSidebarOpened}>
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      {slider ? (
        <List>
          {slider.map((item) => (
            <DrawerList key={item.id} {...item} />
          ))}
        </List>
      ) : (
        <List>{mainListItems}</List>
      )}
    </Drawer>
  );
};

export default SideDrawer;
