import * as React from 'react';
import { Card, CardContent, CardMedia, Typography, Grid, CardActionArea } from '@material-ui/core';

import { useStyles } from './styles';

const MediaCard: React.FC<ICardContentProps> = ({
  title,
  disabled,
  onPress,
  path,
  image,
  description,
}) => {
  const props = { disabled };
  const classes = useStyles(props);

  const hanldeNavigateOnPress = () => {
    onPress(path);
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card className={classes.card}>
        <CardActionArea disabled={disabled} onClick={hanldeNavigateOnPress}>
          <CardMedia className={classes.cardMedia} image={image} title="Image title" />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom variant="h6" component="h4" className={classes.cardTitle}>
              {title}
            </Typography>
            <Typography variant="caption">{description}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default MediaCard;
