import { HttpRequest } from './HttpRequest';
import { fasecoldaUrl, baseUrl } from 'utils/ApiRoutes';

export const getUrlFasecoldaForUpload = async (): Promise<IFasecoldaUrlResponse> => {
  try {
    const url = await new HttpRequest().Get<IFasecoldaUrlResponse>(
      `${baseUrl}${fasecoldaUrl.urlUpload}`
    );

    return url.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const uploadFasecolda = async (
  url: string,
  config: any,
  file?: File | null | undefined
): Promise<any> => {
  try {
    const upload = await new HttpRequest().Put(`${url}`, file, config);

    return upload;
  } catch (err) {
    throw new Error(err);
  }
};
