import * as React from 'react';
import { FormControl, Select, MenuItem, InputBase, Typography } from '@material-ui/core';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

import { useStyles } from './styles';
// import { IPickerSelectProps } from 'types/PickerSelect/PickerSelect';

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  })
)(InputBase);

const PickerSelect: React.FC<IPickerSelectProps> = ({ value, handleChange, items, label }) => {
  // styles
  const classes = useStyles();

  return (
    <>
      <FormControl className={classes.margin}>
        <Typography className={classes.label}>{label}</Typography>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          // value={value}
          // onChange={handleChange}
          input={<BootstrapInput />}>
          {items.map((menuItem: any, index: any) => (
            <MenuItem key={index} value={menuItem.value}>
              {menuItem.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default PickerSelect;
