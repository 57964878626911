import * as React from 'react';
import { useInfoUserState, useUserState, useInfoUserDispatch } from 'context/UserContext';
import { getInitialUserData } from 'services/RootService';

import { adminStructureNavigation, naturalStructureNavigation } from './NavigationStructure';
import Layout from 'components/Layout/LayoutHome';
import { Route, Switch, withRouter } from 'react-router-dom';

const RootNavigation: React.FC = () => {
  // global context
  const infoUserDispatch = useInfoUserDispatch();
  const userInfo = useInfoUserState();
  const { email } = useUserState();
  const role = localStorage.getItem('role');

  React.useEffect(() => {
    // if there is no role assigned
    if (email && userInfo.role === '') {
      getInitialUserData(infoUserDispatch, role);
    }
  }, [email, infoUserDispatch, role, userInfo.role]);

  // navigation structure according the role
  const getRoleStructure = () => {
    switch (userInfo.role) {
      case 'admin':
        return adminStructureNavigation;
      case 'asesor':
        return naturalStructureNavigation;
      default:
        return naturalStructureNavigation;
    }
  };

  const routeComponents = getRoleStructure().route.map(({ path, component }, key) => (
    <Route exact path={path} component={component} key={key} />
  ));

  if (userInfo.role !== '') {
    return (
      <Layout drawerVisible slider={getRoleStructure().slider}>
        <Switch>{routeComponents}</Switch>
      </Layout>
    );
  }
  return null;
};

export default withRouter(RootNavigation);
