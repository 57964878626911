import * as React from 'react';
import { Avatar, Typography, TextField, Button } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useStyles } from './styles';

const UpdateUser: React.FC<ISignNewUser> = ({ toggleModal }) => {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Actualizar usuario
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Nombre"
          name="name"
          autoFocus
          value="David"
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="lastName"
          label="Apellido"
          id="lastName"
          value="Ruiz"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}>
          Actualizar
        </Button>
        <Button fullWidth variant="outlined" className={classes.submit} onClick={toggleModal}>
          Cancelar
        </Button>
      </form>
    </div>
  );
};

export default UpdateUser;
