export const baseError = 'Ha ocurrido un error, vuelva a intentarlo más tarde';

export const INVALID_EMAIL = {
  type: 'warning',
  message: 'No ha ingresado un correo electrónico valido',
};

export const INVALID_PASSWORD = {
  type: 'warning',
  message: 'No se ha ingresado una contraseña',
};

export const REQUIRED_FIELD = 'Este campo es obligatorio.';

export const DATE_INVALID = 'Fecha invalida. Ej: 01/20/2020, día/mes/año';
