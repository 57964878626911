/**
 * Insures types
 */
export const insures = [
  { value: 'BOLIVAR', label: 'BOLIVAR' },
  { value: 'SBS', label: 'SBS' },
  { value: 'EQUIDAD', label: 'EQUIDAD' },
  { value: 'SURA', label: 'SURA' },
  { value: 'ZURICH', label: 'ZURICH' },
  { value: 'HDI', label: 'HDI' },
  { value: 'AXA', label: 'AXA' },
  { value: 'LIBERTY', label: 'LIBERTY' },
];

/**
 * Quote Type
 */
export const quoteTypeData = [
  { value: 'auto', label: 'Auto' },
  { value: 'moto', label: 'Moto' },
];

/**
 * Civil Status
 */
export const civilStatus = [
  { value: 'soltero', label: 'Soltero/a' },
  { value: 'casado', label: 'Casado/a' },
  { value: 'libre', label: 'Únion Libre' },
  { value: 'viudo', label: 'Viudo/a' },
  { value: 'separado', label: 'Separado/a' },
];

/**
 * Document Type
 */
export const documentType = [
  { value: 'cc', label: 'Cédula de Ciudadania' },
  { value: 'ce', label: 'Cédula de Extranjeria' },
  { value: 'nuip', label: 'NUIP' },
  { value: 'ti', label: 'Tarjeta de Identidad' },
];

/**
 * Stratum
 */
export const stratum = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
];

/**
 * Education Level
 */
export const educationLevel = [{ value: 'postgrado', label: 'Postgrado' }];

/**
 * Occupation data
 */
export const occupation = [
  { value: 'empleadoAsalariado', label: 'Empleado/Asalariado' },
  { value: 'independienteComerciante', label: 'Independiente/Comerciante/Socio Propietario' },
  { value: 'estudiante', label: 'Estudiante' },
  { value: 'amaDeCasa', label: 'Ama de Casa' },
  { value: 'pensionado', label: 'Pensionado/a' },
  { value: 'rentista', label: 'Rentista/Inversionista' },
];

/**
 * Profession
 */
export const profession = [
  { value: 'economista', label: 'Economista' },
  { value: 'administrador', label: 'Administrador' },
  { value: 'ingeniero', label: 'Ingeniero' },
];

/**
 * services
 */
export const services = [
  { value: 'particular', label: 'Particular' },
  { value: 'publico', label: 'Público' },
];

/**
 * Use vehicle for public service
 */
export const useForVehiclePublic = [
  { value: 'taxi', label: 'Taxi' },
  { value: 'transporteEscolar', label: 'Transporte Escolar' },
  { value: 'transporteIntermunicipal', label: 'Transporte Intermunicipal' },
  { value: 'alquilerServicios', label: 'Alquiler Servicios Petroleros/Mineros' },
  { value: 'transporteMercanciaPropia', label: 'Transporte Mercancia Propia' },
  { value: 'transporteMercanciaTerceros', label: 'Transporte Mercancia Terceros' },
];

/**
 * Use vehicle for particular service
 */
export const useForVehicleParticular = [
  { value: 'usoPersonal', label: 'Uso Personal/Familiar' },
  { value: 'enseñanza', label: 'Enseñanza' },
  { value: 'ambulancia', label: 'Ambulancia' },
];

/**
 * Default values for react hook form
 */
export const defaultValues: ICarsQuotation = {
  selectedInsurers: insures,
  quoteType: quoteTypeData[0],
  gender: '',
  firstName: '',
  lastName: '',
  fBorn: '',
  civilState: 'separado',
  documentType: 'cc',
  nDocument: '',
  stratum: '6',
  levelEducation: 'postgrado',
  occupation: 'empleadoAsalariado',
  profession: 'economista',
  tVehicle: '',
  service: 'particular',
  useVehicle: 'usoPersonal',
  cityCirculation: '',
  optionalCity: '',
  checkAccesories: false,
  valueAccesories: '',
  licenseVehicle: '',
  codeFasecolda: '',
  modelo: '',
  brand: '',
  ref1: '',
  ref2: '',
  ref3: '',
  valueInsured: '',
};
