import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 'bold',
  },
  dateLabel: {
    color: theme.palette.text.secondary,
  },
  headerTitle: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 320,
  },
  quoteAgain: {
    alignSelf: 'center',
  },
  fullWidthButton: {
    width: '100%',
    marginTop: 10,
  },
}));
