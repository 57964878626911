import React, { createElement, ComponentType } from 'react';
import { Switch, Route, Redirect, BrowserRouter, RouteProps } from 'react-router-dom';

import { useUserState } from './context/UserContext';
import Login from './pages/Login/Login';
import Error from './pages/Error/Error';
import Recover from './pages/Recover/Recover';
import RootNavigation from 'navigation/RootNavigation';

interface IRouteProps extends RouteProps {
  path: string;
  component: any;
}

function App() {
  const { isAuthenticated } = useUserState();

  const PrivateRoute: ComponentType<IRouteProps> = ({ component, ...rest }) => (
    <Route
      {...rest}
      // tslint:disable-next-line: jsx-no-lambda
      render={(props: any) =>
        isAuthenticated ? (
          createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/iniciar-sesion',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );

  const PublicRoute: ComponentType<IRouteProps> = ({ component, ...rest }) => (
    <Route
      {...rest}
      // tslint:disable-next-line: jsx-no-lambda
      render={(props: any) =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        ) : (
          createElement(component, props)
        )
      }
    />
  );

  const redirectMainPath = () => <Redirect to="/app/inicio" />;

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={redirectMainPath} />
        <PrivateRoute path="/app" component={RootNavigation} />
        <PublicRoute path="/iniciar-sesion" component={Login} />
        <PublicRoute path="/recuperar" component={Recover} />
        <Route component={Error} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
