import * as React from 'react';
import { Typography, Divider } from '@material-ui/core';

import { useStyles } from './styles';
import { ISubTitle } from 'types/CarsQuotation/IUserData';

const SubTitle: React.FC<ISubTitle> = ({ title }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" gutterBottom className={classes.title}>
        {title}
      </Typography>
      <Divider className={classes.divider} />
    </>
  );
};

export default SubTitle;
