/**
 * Cities
 */
export const cities = [
  {
    value: 'antioquia',
    label: 'Antioquia',
  },
  {
    value: 'antioquia-abejorral',
    label: 'Abejorral - Antioquia',
  },
  {
    value: 'antioquia-abriaqui',
    label: 'Abriaquí - Antioquia',
  },
  {
    value: 'antioquia-alejandria',
    label: 'Alejandría - Antioquia',
  },
  {
    value: 'antioquia-amaga',
    label: 'Amagá - Antioquia',
  },
  {
    value: 'antioquia-amalfi',
    label: 'Amalfi - Antioquia',
  },
  {
    value: 'antioquia-andes',
    label: 'Andes - Antioquia',
  },
  {
    value: 'antioquia-angelopolis',
    label: 'Angelópolis - Antioquia',
  },
  {
    value: 'antioquia-angostura',
    label: 'Angostura - Antioquia',
  },
  {
    value: 'antioquia-anori',
    label: 'Anorí - Antioquia',
  },
  {
    value: 'antioquia-anza',
    label: 'Anzá - Antioquia',
  },
  {
    value: 'antioquia-apartado',
    label: 'Apartadó - Antioquia',
  },
  {
    value: 'antioquia-arboletes',
    label: 'Arboletes - Antioquia',
  },
  {
    value: 'antioquia-argelia',
    label: 'Argelia - Antioquia',
  },
  {
    value: 'antioquia-armenia',
    label: 'Armenia - Antioquia',
  },
  {
    value: 'antioquia-barbosa',
    label: 'Barbosa - Antioquia',
  },
  {
    value: 'antioquia-bello',
    label: 'Bello - Antioquia',
  },
  {
    value: 'antioquia-belmira',
    label: 'Belmira - Antioquia',
  },
  {
    value: 'antioquia-betania',
    label: 'Betania - Antioquia',
  },
  {
    value: 'antioquia-betulia',
    label: 'Betulia - Antioquia',
  },
  {
    value: 'antioquia-briceno',
    label: 'Briceño - Antioquia',
  },
  {
    value: 'antioquia-buritica',
    label: 'Buriticá - Antioquia',
  },
  {
    value: 'antioquia-caicedo',
    label: 'Caicedo - Antioquia',
  },
  {
    value: 'antioquia-caldas',
    label: 'Caldas - Antioquia - Caldas',
  },
  {
    value: 'antioquia-campamento',
    label: 'Campamento - Antioquia',
  },
  {
    value: 'antioquia-caracoli',
    label: 'Caracolí - Antioquia',
  },
  {
    value: 'antioquia-caramanta',
    label: 'Caramanta - Antioquia',
  },
  {
    value: 'antioquia-carepa',
    label: 'Carepa - Antioquia',
  },
  {
    value: 'antioquia-carolina',
    label: 'Carolina - Antioquia',
  },
  {
    value: 'antioquia-caucasia',
    label: 'Caucasia - Antioquia',
  },
  {
    value: 'antioquia-canasgordas',
    label: 'Cañasgordas - Antioquia',
  },
  {
    value: 'antioquia-chigorodo',
    label: 'Chigorodó - Antioquia',
  },
  {
    value: 'antioquia-cisneros',
    label: 'Cisneros - Antioquia',
  },
  {
    value: 'antioquia-ciudadbolivar',
    label: 'Ciudad Bolívar - Antioquia',
  },
  {
    value: 'antioquia-cocorna',
    label: 'Cocorná - Antioquia',
  },
  {
    value: 'antioquia-concepcion',
    label: 'Concepción - Antioquia',
  },
  {
    value: 'antioquia-concordia',
    label: 'Concordia - Antioquia',
  },
  {
    value: 'antioquia-copacabana',
    label: 'Copacabana - Antioquia',
  },
  {
    value: 'antioquia-caceres',
    label: 'Cáceres - Antioquia',
  },
  {
    value: 'antioquia-dabeiba',
    label: 'Dabeiba - Antioquia',
  },
  {
    value: 'antioquia-donmatias',
    label: 'Donmatías - Antioquia',
  },
  {
    value: 'antioquia-ebejico',
    label: 'Ebéjico - Antioquia',
  },
  {
    value: 'antioquia-elbagre',
    label: 'El Bagre - Antioquia',
  },
  {
    value: 'antioquia-elcarmendeviboral',
    label: 'El Carmen De Viboral - Antioquia',
  },
  {
    value: 'antioquia-elsantuario',
    label: 'El Santuario - Antioquia',
  },
  {
    value: 'antioquia-entrerrios',
    label: 'Entrerríos - Antioquia',
  },
  {
    value: 'antioquia-envigado',
    label: 'Envigado - Antioquia',
  },
  {
    value: 'antioquia-fredonia',
    label: 'Fredonia - Antioquia',
  },
  {
    value: 'antioquia-frontino',
    label: 'Frontino - Antioquia',
  },
  {
    value: 'antioquia-giraldo',
    label: 'Giraldo - Antioquia',
  },
  {
    value: 'antioquia-girardota',
    label: 'Girardota - Antioquia',
  },
  {
    value: 'antioquia-granada',
    label: 'Granada - Antioquia',
  },
  {
    value: 'antioquia-guadalupe',
    label: 'Guadalupe - Antioquia',
  },
  {
    value: 'antioquia-guarne',
    label: 'Guarne - Antioquia',
  },
  {
    value: 'antioquia-guatape',
    label: 'Guatapé - Antioquia',
  },
  {
    value: 'antioquia-gomezplata',
    label: 'Gómez Plata - Antioquia',
  },
  {
    value: 'antioquia-heliconia',
    label: 'Heliconia - Antioquia',
  },
  {
    value: 'antioquia-hispania',
    label: 'Hispania - Antioquia',
  },
  {
    value: 'antioquia-itagui',
    label: 'Itagüí - Antioquia',
  },
  {
    value: 'antioquia-ituango',
    label: 'Ituango - Antioquia',
  },
  {
    value: 'antioquia-jardin',
    label: 'Jardín - Antioquia',
  },
  {
    value: 'antioquia-jerico',
    label: 'Jericó - Antioquia',
  },
  {
    value: 'antioquia-laceja',
    label: 'La Ceja - Antioquia',
  },
  {
    value: 'antioquia-laestrella',
    label: 'La Estrella - Antioquia',
  },
  {
    value: 'antioquia-lapintada',
    label: 'La Pintada - Antioquia',
  },
  {
    value: 'antioquia-launion',
    label: 'La Unión - Antioquia',
  },
  {
    value: 'antioquia-liborina',
    label: 'Liborina - Antioquia',
  },
  {
    value: 'antioquia-maceo',
    label: 'Maceo - Antioquia',
  },
  {
    value: 'antioquia-marinilla',
    label: 'Marinilla - Antioquia',
  },
  {
    value: 'antioquia-medellin',
    label: 'Medellín - Antioquia',
  },
  {
    value: 'antioquia-montebello',
    label: 'Montebello - Antioquia',
  },
  {
    value: 'antioquia-murindo',
    label: 'Murindó - Antioquia',
  },
  {
    value: 'antioquia-mutata',
    label: 'Mutatá - Antioquia',
  },
  {
    value: 'antioquia-narino',
    label: 'Nariño - Antioquia - Nariño',
  },
  {
    value: 'antioquia-nechi',
    label: 'Nechí - Antioquia',
  },
  {
    value: 'antioquia-necocli',
    label: 'Necoclí - Antioquia',
  },
  {
    value: 'antioquia-olaya',
    label: 'Olaya - Antioquia',
  },
  {
    value: 'antioquia-peque',
    label: 'Peque - Antioquia',
  },
  {
    value: 'antioquia-penol',
    label: 'Peñol - Antioquia',
  },
  {
    value: 'antioquia-pueblorrico',
    label: 'Pueblorrico - Antioquia',
  },
  {
    value: 'antioquia-puertoberrio',
    label: 'Puerto Berrío - Antioquia',
  },
  {
    value: 'antioquia-puertonare',
    label: 'Puerto Nare - Antioquia',
  },
  {
    value: 'antioquia-puertotriunfo',
    label: 'Puerto Triunfo - Antioquia',
  },
  {
    value: 'antioquia-remedios',
    label: 'Remedios - Antioquia',
  },
  {
    value: 'antioquia-retiro',
    label: 'Retiro - Antioquia',
  },
  {
    value: 'antioquia-rionegro',
    label: 'Rionegro - Antioquia',
  },
  {
    value: 'antioquia-sabanalarga',
    label: 'Sabanalarga - Antioquia',
  },
  {
    value: 'antioquia-sabaneta',
    label: 'Sabaneta - Antioquia',
  },
  {
    value: 'antioquia-salgar',
    label: 'Salgar - Antioquia',
  },
  {
    value: 'antioquia-sanandresdecuerquia',
    label: 'San Andrés De Cuerquía - Antioquia',
  },
  {
    value: 'antioquia-sancarlos',
    label: 'San Carlos - Antioquia',
  },
  {
    value: 'antioquia-sanfrancisco',
    label: 'San Francisco - Antioquia',
  },
  {
    value: 'antioquia-sanjeronimo',
    label: 'San Jerónimo - Antioquia',
  },
  {
    value: 'antioquia-sanjosedela montana',
    label: 'San José De La Montaña - Antioquia',
  },
  {
    value: 'antioquia-sanjuandeuraba',
    label: 'San Juan De Urabá - Antioquia',
  },
  {
    value: 'antioquia-sanluis',
    label: 'San Luis - Antioquia',
  },
  {
    value: 'antioquia-sanpedrodelos milagros',
    label: 'San Pedro De Los Milagros - Antioquia',
  },
  {
    value: 'antioquia-sanpedrodeuraba',
    label: 'San Pedro De Urabá - Antioquia',
  },
  {
    value: 'antioquia-sanrafael',
    label: 'San Rafael - Antioquia',
  },
  {
    value: 'antioquia-sanroque',
    label: 'San Roque - Antioquia',
  },
  {
    value: 'antioquia-sanvicenteferrer',
    label: 'San Vicente Ferrer - Antioquia',
  },
  {
    value: 'antioquia-santabarbara',
    label: 'Santa Bárbara - Antioquia',
  },
  {
    value: 'antioquia-santafedeantioquia',
    label: 'Santa Fé De Antioquia - Antioquia',
  },
  {
    value: 'antioquia-santarosadeosos',
    label: 'Santa Rosa De Osos - Antioquia',
  },
  {
    value: 'antioquia-santodomingo',
    label: 'Santo Domingo - Antioquia',
  },
  {
    value: 'antioquia-segovia',
    label: 'Segovia - Antioquia',
  },
  {
    value: 'antioquia-sonson',
    label: 'Sonsón - Antioquia',
  },
  {
    value: 'antioquia-sopetran',
    label: 'Sopetrán - Antioquia',
  },
  {
    value: 'antioquia-taraza',
    label: 'Tarazá - Antioquia',
  },
  {
    value: 'antioquia-tarso',
    label: 'Tarso - Antioquia',
  },
  {
    value: 'antioquia-titiribi',
    label: 'Titiribí - Antioquia',
  },
  {
    value: 'antioquia-toledo',
    label: 'Toledo - Antioquia',
  },
  {
    value: 'antioquia-turbo',
    label: 'Turbo - Antioquia',
  },
  {
    value: 'antioquia-tamesis',
    label: 'Támesis - Antioquia',
  },
  {
    value: 'antioquia-uramita',
    label: 'Uramita - Antioquia',
  },
  {
    value: 'antioquia-urrao',
    label: 'Urrao - Antioquia',
  },
  {
    value: 'antioquia-valdivia',
    label: 'Valdivia - Antioquia',
  },
  {
    value: 'antioquia-valparaiso',
    label: 'Valparaíso - Antioquia',
  },
  {
    value: 'antioquia-vegachi',
    label: 'Vegachí - Antioquia',
  },
  {
    value: 'antioquia-venecia',
    label: 'Venecia - Antioquia',
  },
  {
    value: 'antioquia-vigiadelfuerte',
    label: 'Vigía Del Fuerte - Antioquia',
  },
  {
    value: 'antioquia-yali',
    label: 'Yalí - Antioquia',
  },
  {
    value: 'antioquia-yarumal',
    label: 'Yarumal - Antioquia',
  },
  {
    value: 'antioquia-yolombo',
    label: 'Yolombó - Antioquia',
  },
  {
    value: 'antioquia-yondo',
    label: 'Yondó - Antioquia',
  },
  {
    value: 'antioquia-zaragoza',
    label: 'Zaragoza - Antioquia',
  },
  {
    value: 'atlantico',
    label: 'Atlántico',
  },
  {
    value: 'atlantico-baranoa',
    label: 'Baranoa - Atlántico',
  },
  {
    value: 'atlantico-barranquilla',
    label: 'Barranquilla - Atlántico',
  },
  {
    value: 'atlantico-campodelacruz',
    label: 'Campo De La Cruz - Atlántico',
  },
  {
    value: 'atlantico-candelaria',
    label: 'Candelaria - Atlántico',
  },
  {
    value: 'atlantico-galapa',
    label: 'Galapa - Atlántico',
  },
  {
    value: 'atlantico-juandeacosta',
    label: 'Juan De Acosta - Atlántico',
  },
  {
    value: 'atlantico-luruaco',
    label: 'Luruaco - Atlántico',
  },
  {
    value: 'atlantico-malambo',
    label: 'Malambo - Atlántico',
  },
  {
    value: 'atlantico-manati',
    label: 'Manatí - Atlántico',
  },
  {
    value: 'atlantico-palmardevarela',
    label: 'Palmar De Varela - Atlántico',
  },
  {
    value: 'atlantico-piojo',
    label: 'Piojó - Atlántico',
  },
  {
    value: 'atlantico-polonuevo',
    label: 'Polonuevo - Atlántico',
  },
  {
    value: 'atlantico-ponedera',
    label: 'Ponedera - Atlántico',
  },
  {
    value: 'atlantico-puertocolombia',
    label: 'Puerto Colombia - Atlántico',
  },
  {
    value: 'atlantico-repelon',
    label: 'Repelón - Atlántico',
  },
  {
    value: 'atlantico-sabanagrande',
    label: 'Sabanagrande - Atlántico',
  },
  {
    value: 'atlantico-sabanalarga',
    label: 'Sabanalarga - Atlántico',
  },
  {
    value: 'atlantico-santalucia',
    label: 'Santa Lucía - Atlántico',
  },
  {
    value: 'atlantico-santotomas',
    label: 'Santo Tomás - Atlántico',
  },
  {
    value: 'atlantico-soledad',
    label: 'Soledad - Atlántico',
  },
  {
    value: 'atlantico-suan',
    label: 'Suan - Atlántico',
  },
  {
    value: 'atlantico-tubara',
    label: 'Tubará - Atlántico',
  },
  {
    value: 'atlantico-usiacuri',
    label: 'Usiacurí - Atlántico',
  },
  {
    value: 'bogota-bogota',
    label: 'Bogotá, D.C.',
  },
  {
    value: 'bolivar',
    label: 'Bolívar',
  },
  {
    value: 'bolivar-achi',
    label: 'Achí - Bolívar',
  },
  {
    value: 'bolivar-altosdelrosario',
    label: 'Altos Del Rosario - Bolívar',
  },
  {
    value: 'bolivar-arenal',
    label: 'Arenal - Bolívar',
  },
  {
    value: 'bolivar-arjona',
    label: 'Arjona - Bolívar',
  },
  {
    value: 'bolivar-arroyohondo',
    label: 'Arroyohondo - Bolívar',
  },
  {
    value: 'bolivar-barrancodeloba',
    label: 'Barranco De Loba - Bolívar',
  },
  {
    value: 'bolivar-calamar',
    label: 'Calamar - Bolívar',
  },
  {
    value: 'bolivar-cantagallo',
    label: 'Cantagallo - Bolívar',
  },
  {
    value: 'bolivar-cartagenadeindias',
    label: 'Cartagena De Indias - Bolívar',
  },
  {
    value: 'bolivar-cicuco',
    label: 'Cicuco - Bolívar',
  },
  {
    value: 'bolivar-clemencia',
    label: 'Clemencia - Bolívar',
  },
  {
    value: 'bolivar-cordoba',
    label: 'Córdoba - Bolívar',
  },
  {
    value: 'bolivar-elcarmendebolivar',
    label: 'El Carmen De Bolívar - Bolívar',
  },
  {
    value: 'bolivar-elguamo',
    label: 'El Guamo - Bolívar',
  },
  {
    value: 'bolivar-elpenon',
    label: 'El Peñón - Bolívar',
  },
  {
    value: 'bolivar-hatillodeloba',
    label: 'Hatillo De Loba - Bolívar',
  },
  {
    value: 'bolivar-magangue',
    label: 'Magangué - Bolívar',
  },
  {
    value: 'bolivar-mahates',
    label: 'Mahates - Bolívar',
  },
  {
    value: 'bolivar-margarita',
    label: 'Margarita - Bolívar',
  },
  {
    value: 'bolivar-marialabaja',
    label: 'María La Baja - Bolívar',
  },
  {
    value: 'bolivar-mompos',
    label: 'Mompós - Bolívar',
  },
  {
    value: 'bolivar-montecristo',
    label: 'Montecristo - Bolívar',
  },
  {
    value: 'bolivar-morales',
    label: 'Morales - Bolívar',
  },
  {
    value: 'bolivar-norosi',
    label: 'Norosí - Bolívar',
  },
  {
    value: 'bolivar-pinillos',
    label: 'Pinillos - Bolívar',
  },
  {
    value: 'bolivar-regidor',
    label: 'Regidor - Bolívar',
  },
  {
    value: 'bolivar-rioviejo',
    label: 'Río Viejo - Bolívar',
  },
  {
    value: 'bolivar-sancristobal',
    label: 'San Cristóbal - Bolívar',
  },
  {
    value: 'bolivar-sanestanislao',
    label: 'San Estanislao - Bolívar',
  },
  {
    value: 'bolivar-sanfernando',
    label: 'San Fernando - Bolívar',
  },
  {
    value: 'bolivar-sanjacinto',
    label: 'San Jacinto - Bolívar',
  },
  {
    value: 'bolivar-sanjacintodelcauca',
    label: 'San Jacinto Del Cauca - Bolívar',
  },
  {
    value: 'bolivar-sanjuannepomuceno',
    label: 'San Juan Nepomuceno - Bolívar',
  },
  {
    value: 'bolivar-sanmartindeloba',
    label: 'San Martín De Loba - Bolívar',
  },
  {
    value: 'bolivar-sanpablo',
    label: 'San Pablo - Bolívar',
  },
  {
    value: 'bolivar-santacatalina',
    label: 'Santa Catalina - Bolívar',
  },
  {
    value: 'bolivar-santarosa',
    label: 'Santa Rosa - Bolívar',
  },
  {
    value: 'bolivar-santarosadelsur',
    label: 'Santa Rosa Del Sur - Bolívar',
  },
  {
    value: 'bolivar-simiti',
    label: 'Simití - Bolívar',
  },
  {
    value: 'bolivar-soplaviento',
    label: 'Soplaviento - Bolívar',
  },
  {
    value: 'bolivar-talaiguanuevo',
    label: 'Talaigua Nuevo - Bolívar',
  },
  {
    value: 'bolivar-tiquisio',
    label: 'Tiquisio - Bolívar',
  },
  {
    value: 'bolivar-turbaco',
    label: 'Turbaco - Bolívar',
  },
  {
    value: 'bolivar-turbana',
    label: 'Turbaná - Bolívar',
  },
  {
    value: 'bolivar-villanueva',
    label: 'Villanueva - Bolívar',
  },
  {
    value: 'bolivar-zambrano',
    label: 'Zambrano - Bolívar',
  },
  {
    value: 'boyaca',
    label: 'Boyacá',
  },
  {
    value: 'boyaca-almeida',
    label: 'Almeida - Boyacá',
  },
  {
    value: 'boyaca-aquitania',
    label: 'Aquitania - Boyacá',
  },
  {
    value: 'boyaca-arcabuco',
    label: 'Arcabuco - Boyacá',
  },
  {
    value: 'boyaca-belen',
    label: 'Belén - Boyacá',
  },
  {
    value: 'boyaca-berbeo',
    label: 'Berbeo - Boyacá',
  },
  {
    value: 'boyaca-beteitiva',
    label: 'Betéitiva - Boyacá',
  },
  {
    value: 'boyaca-boavita',
    label: 'Boavita - Boyacá',
  },
  {
    value: 'boyaca-boyaca',
    label: 'Boyacá - Boyacá',
  },
  {
    value: 'boyaca-briceno',
    label: 'Briceño - Boyacá',
  },
  {
    value: 'boyaca-buenavista',
    label: 'Buenavista - Boyacá',
  },
  {
    value: 'boyaca-busbanza',
    label: 'Busbanzá - Boyacá',
  },
  {
    value: 'boyaca-caldas',
    label: 'Caldas - Boyacá - Caldas',
  },
  {
    value: 'boyaca-campohermoso',
    label: 'Campohermoso - Boyacá',
  },
  {
    value: 'boyaca-cerinza',
    label: 'Cerinza - Boyacá',
  },
  {
    value: 'boyaca-chinavita',
    label: 'Chinavita - Boyacá',
  },
  {
    value: 'boyaca-chiquinquira',
    label: 'Chiquinquirá - Boyacá',
  },
  {
    value: 'boyaca-chiscas',
    label: 'Chiscas - Boyacá',
  },
  {
    value: 'boyaca-chita',
    label: 'Chita - Boyacá',
  },
  {
    value: 'boyaca-chitaraque',
    label: 'Chitaraque - Boyacá',
  },
  {
    value: 'boyaca-chivata',
    label: 'Chivatá - Boyacá',
  },
  {
    value: 'boyaca-chivor',
    label: 'Chivor - Boyacá',
  },
  {
    value: 'boyaca-chiquiza',
    label: 'Chíquiza - Boyacá',
  },
  {
    value: 'boyaca-cienega',
    label: 'Ciénega - Boyacá',
  },
  {
    value: 'boyaca-coper',
    label: 'Coper - Boyacá',
  },
  {
    value: 'boyaca-corrales',
    label: 'Corrales - Boyacá',
  },
  {
    value: 'boyaca-covarachia',
    label: 'Covarachía - Boyacá',
  },
  {
    value: 'boyaca-cubara',
    label: 'Cubará - Boyacá',
  },
  {
    value: 'boyaca-cucaita',
    label: 'Cucaita - Boyacá',
  },
  {
    value: 'boyaca-cuitiva',
    label: 'Cuítiva - Boyacá',
  },
  {
    value: 'boyaca-combita',
    label: 'Cómbita - Boyacá',
  },
  {
    value: 'boyaca-duitama',
    label: 'Duitama - Boyacá',
  },
  {
    value: 'boyaca-elcocuy',
    label: 'El Cocuy - Boyacá',
  },
  {
    value: 'boyaca-elespino',
    label: 'El Espino - Boyacá',
  },
  {
    value: 'boyaca-firavitoba',
    label: 'Firavitoba - Boyacá',
  },
  {
    value: 'boyaca-floresta',
    label: 'Floresta - Boyacá',
  },
  {
    value: 'boyaca-gachantiva',
    label: 'Gachantivá - Boyacá',
  },
  {
    value: 'boyaca-garagoa',
    label: 'Garagoa - Boyacá',
  },
  {
    value: 'boyaca-guacamayas',
    label: 'Guacamayas - Boyacá',
  },
  {
    value: 'boyaca-guateque',
    label: 'Guateque - Boyacá',
  },
  {
    value: 'boyaca-guayata',
    label: 'Guayatá - Boyacá',
  },
  {
    value: 'boyaca-gameza',
    label: 'Gámeza - Boyacá',
  },
  {
    value: 'boyaca-guicandelasierra',
    label: 'Güicán De La Sierra - Boyacá',
  },
  {
    value: 'boyaca-iza',
    label: 'Iza - Boyacá',
  },
  {
    value: 'boyaca-jenesano',
    label: 'Jenesano - Boyacá',
  },
  {
    value: 'boyaca-jerico',
    label: 'Jericó - Boyacá',
  },
  {
    value: 'boyaca-lacapilla',
    label: 'La Capilla - Boyacá',
  },
  {
    value: 'boyaca-lauvita',
    label: 'La Uvita - Boyacá',
  },
  {
    value: 'boyaca-lavictoria',
    label: 'La Victoria - Boyacá',
  },
  {
    value: 'boyaca-labranzagrande',
    label: 'Labranzagrande - Boyacá',
  },
  {
    value: 'boyaca-macanal',
    label: 'Macanal - Boyacá',
  },
  {
    value: 'boyaca-maripi',
    label: 'Maripí - Boyacá',
  },
  {
    value: 'boyaca-miraflores',
    label: 'Miraflores - Boyacá',
  },
  {
    value: 'boyaca-mongua',
    label: 'Mongua - Boyacá',
  },
  {
    value: 'boyaca-mongui',
    label: 'Monguí - Boyacá',
  },
  {
    value: 'boyaca-moniquira',
    label: 'Moniquirá - Boyacá',
  },
  {
    value: 'boyaca-motavita',
    label: 'Motavita - Boyacá',
  },
  {
    value: 'boyaca-muzo',
    label: 'Muzo - Boyacá',
  },
  {
    value: 'boyaca-nobsa',
    label: 'Nobsa - Boyacá',
  },
  {
    value: 'boyaca-nuevocolon',
    label: 'Nuevo Colón - Boyacá',
  },
  {
    value: 'boyaca-oicata',
    label: 'Oicatá - Boyacá',
  },
  {
    value: 'boyaca-otanche',
    label: 'Otanche - Boyacá',
  },
  {
    value: 'boyaca-pachavita',
    label: 'Pachavita - Boyacá',
  },
  {
    value: 'boyaca-paipa',
    label: 'Paipa - Boyacá',
  },
  {
    value: 'boyaca-pajarito',
    label: 'Pajarito - Boyacá',
  },
  {
    value: 'boyaca-panqueba',
    label: 'Panqueba - Boyacá',
  },
  {
    value: 'boyaca-pauna',
    label: 'Pauna - Boyacá',
  },
  {
    value: 'boyaca-paya',
    label: 'Paya - Boyacá',
  },
  {
    value: 'boyaca-pazderio',
    label: 'Paz De Río - Boyacá',
  },
  {
    value: 'boyaca-pesca',
    label: 'Pesca - Boyacá',
  },
  {
    value: 'boyaca-pisba',
    label: 'Pisba - Boyacá',
  },
  {
    value: 'boyaca-puertoboyaca',
    label: 'Puerto Boyacá - Boyacá',
  },
  {
    value: 'boyaca-paez',
    label: 'Páez - Boyacá',
  },
  {
    value: 'boyaca-quipama',
    label: 'Quípama - Boyacá',
  },
  {
    value: 'boyaca-ramiriqui',
    label: 'Ramiriquí - Boyacá',
  },
  {
    value: 'boyaca-rondon',
    label: 'Rondón - Boyacá',
  },
  {
    value: 'boyaca-raquira',
    label: 'Ráquira - Boyacá',
  },
  {
    value: 'boyaca-saboya',
    label: 'Saboyá - Boyacá',
  },
  {
    value: 'boyaca-samaca',
    label: 'Samacá - Boyacá',
  },
  {
    value: 'boyaca-saneduardo',
    label: 'San Eduardo - Boyacá',
  },
  {
    value: 'boyaca-sanjosedepare',
    label: 'San José De Pare - Boyacá',
  },
  {
    value: 'boyaca-sanluisdegaceno',
    label: 'San Luis De Gaceno - Boyacá',
  },
  {
    value: 'boyaca-sanmateo',
    label: 'San Mateo - Boyacá',
  },
  {
    value: 'boyaca-sanmigueldesema',
    label: 'San Miguel De Sema - Boyacá',
  },
  {
    value: 'boyaca-sanpablodeborbur',
    label: 'San Pablo De Borbur - Boyacá',
  },
  {
    value: 'boyaca-santamaria',
    label: 'Santa María - Boyacá',
  },
  {
    value: 'boyaca-santarosadeviterbo',
    label: 'Santa Rosa De Viterbo - Boyacá',
  },
  {
    value: 'boyaca-santasofia',
    label: 'Santa Sofía - Boyacá',
  },
  {
    value: 'boyaca-santana',
    label: 'Santana - Boyacá',
  },
  {
    value: 'boyaca-sativanorte',
    label: 'Sativanorte - Boyacá',
  },
  {
    value: 'boyaca-sativasur',
    label: 'Sativasur - Boyacá',
  },
  {
    value: 'boyaca-siachoque',
    label: 'Siachoque - Boyacá',
  },
  {
    value: 'boyaca-soata',
    label: 'Soatá - Boyacá',
  },
  {
    value: 'boyaca-socha',
    label: 'Socha - Boyacá',
  },
  {
    value: 'boyaca-socota',
    label: 'Socotá - Boyacá',
  },
  {
    value: 'boyaca-sogamoso',
    label: 'Sogamoso - Boyacá',
  },
  {
    value: 'boyaca-somondoco',
    label: 'Somondoco - Boyacá',
  },
  {
    value: 'boyaca-sora',
    label: 'Sora - Boyacá',
  },
  {
    value: 'boyaca-soraca',
    label: 'Soracá - Boyacá',
  },
  {
    value: 'boyaca-sotaquira',
    label: 'Sotaquirá - Boyacá',
  },
  {
    value: 'boyaca-susacon',
    label: 'Susacón - Boyacá',
  },
  {
    value: 'boyaca-sutamarchan',
    label: 'Sutamarchán - Boyacá',
  },
  {
    value: 'boyaca-sutatenza',
    label: 'Sutatenza - Boyacá',
  },
  {
    value: 'boyaca-sachica',
    label: 'Sáchica - Boyacá',
  },
  {
    value: 'boyaca-tasco',
    label: 'Tasco - Boyacá',
  },
  {
    value: 'boyaca-tenza',
    label: 'Tenza - Boyacá',
  },
  {
    value: 'boyaca-tibana',
    label: 'Tibaná - Boyacá',
  },
  {
    value: 'boyaca-tibasosa',
    label: 'Tibasosa - Boyacá',
  },
  {
    value: 'boyaca-tinjaca',
    label: 'Tinjacá - Boyacá',
  },
  {
    value: 'boyaca-tipacoque',
    label: 'Tipacoque - Boyacá',
  },
  {
    value: 'boyaca-toca',
    label: 'Toca - Boyacá',
  },
  {
    value: 'boyaca-togui',
    label: 'Togüí - Boyacá',
  },
  {
    value: 'boyaca-tota',
    label: 'Tota - Boyacá',
  },
  {
    value: 'boyaca-tunja',
    label: 'Tunja - Boyacá',
  },
  {
    value: 'boyaca-tunungua',
    label: 'Tununguá - Boyacá',
  },
  {
    value: 'boyaca-turmeque',
    label: 'Turmequé - Boyacá',
  },
  {
    value: 'boyaca-tuta',
    label: 'Tuta - Boyacá',
  },
  {
    value: 'boyaca-tutaza',
    label: 'Tutazá - Boyacá',
  },
  {
    value: 'boyaca-topaga',
    label: 'Tópaga - Boyacá',
  },
  {
    value: 'boyaca-ventaquemada',
    label: 'Ventaquemada - Boyacá',
  },
  {
    value: 'boyaca-villadeleyva',
    label: 'Villa De Leyva - Boyacá',
  },
  {
    value: 'boyaca-viracacha',
    label: 'Viracachá - Boyacá',
  },
  {
    value: 'boyaca-zetaquira',
    label: 'Zetaquira - Boyacá',
  },
  {
    value: 'boyaca-umbita',
    label: 'Úmbita - Boyacá',
  },
  {
    value: 'caldas',
    label: 'Caldas',
  },
  {
    value: 'caldas-aguadas',
    label: 'Aguadas - Caldas',
  },
  {
    value: 'caldas-anserma',
    label: 'Anserma - Caldas',
  },
  {
    value: 'caldas-aranzazu',
    label: 'Aranzazu - Caldas',
  },
  {
    value: 'caldas-belalcazar',
    label: 'Belalcázar - Caldas',
  },
  {
    value: 'caldas-chinchina',
    label: 'Chinchiná - Caldas',
  },
  {
    value: 'caldas-filadelfia',
    label: 'Filadelfia - Caldas',
  },
  {
    value: 'caldas-ladorada',
    label: 'La Dorada - Caldas',
  },
  {
    value: 'caldas-lamerced',
    label: 'La Merced - Caldas',
  },
  {
    value: 'caldas-manizales',
    label: 'Manizales - Caldas',
  },
  {
    value: 'caldas-manzanares',
    label: 'Manzanares - Caldas',
  },
  {
    value: 'caldas-marmato',
    label: 'Marmato - Caldas',
  },
  {
    value: 'caldas-marquetalia',
    label: 'Marquetalia - Caldas',
  },
  {
    value: 'caldas-marulanda',
    label: 'Marulanda - Caldas',
  },
  {
    value: 'caldas-neira',
    label: 'Neira - Caldas',
  },
  {
    value: 'caldas-norcasia',
    label: 'Norcasia - Caldas',
  },
  {
    value: 'caldas-palestina',
    label: 'Palestina - Caldas',
  },
  {
    value: 'caldas-pensilvania',
    label: 'Pensilvania - Caldas',
  },
  {
    value: 'caldas-pacora',
    label: 'Pácora - Caldas',
  },
  {
    value: 'caldas-riosucio',
    label: 'Riosucio - Caldas',
  },
  {
    value: 'caldas-risaralda',
    label: 'Risaralda - Caldas - Risaralda',
  },
  {
    value: 'caldas-salamina',
    label: 'Salamina - Caldas',
  },
  {
    value: 'caldas-samana',
    label: 'Samaná - Caldas',
  },
  {
    value: 'caldas-sanjose',
    label: 'San José - Caldas',
  },
  {
    value: 'caldas-supia',
    label: 'Supía - Caldas',
  },
  {
    value: 'caldas-victoria',
    label: 'Victoria - Caldas',
  },
  {
    value: 'caldas-villamaria',
    label: 'Villamaría - Caldas',
  },
  {
    value: 'caldas-viterbo',
    label: 'Viterbo - Caldas',
  },
  {
    value: 'caqueta',
    label: 'Caquetá - Caquetá',
  },
  {
    value: 'caqueta-albania',
    label: 'Albania - Caquetá',
  },
  {
    value: 'caqueta-belendelosandaquies',
    label: 'Belén De Los Andaquíes - Caquetá',
  },
  {
    value: 'caqueta-cartagenadelchaira',
    label: 'Cartagena Del Chairá - Caquetá',
  },
  {
    value: 'caqueta-curillo',
    label: 'Curillo - Caquetá',
  },
  {
    value: 'caqueta-eldoncello',
    label: 'El Doncello - Caquetá',
  },
  {
    value: 'caqueta-elpaujil',
    label: 'El Paujíl - Caquetá',
  },
  {
    value: 'caqueta-florencia',
    label: 'Florencia - Caquetá',
  },
  {
    value: 'caqueta-lamontanita',
    label: 'La Montañita - Caquetá',
  },
  {
    value: 'caqueta-milan',
    label: 'Milán - Caquetá',
  },
  {
    value: 'caqueta-morelia',
    label: 'Morelia - Caquetá',
  },
  {
    value: 'caqueta-puertorico',
    label: 'Puerto Rico - Caquetá',
  },
  {
    value: 'caqueta-sanjosedelfragua',
    label: 'San José Del Fragua - Caquetá',
  },
  {
    value: 'caqueta-sanvicentedelcaguan',
    label: 'San Vicente Del Caguán - Caquetá',
  },
  {
    value: 'caqueta-solano',
    label: 'Solano - Caquetá',
  },
  {
    value: 'caqueta-solita',
    label: 'Solita - Caquetá',
  },
  {
    value: 'caqueta-valparaiso',
    label: 'Valparaíso - Caquetá',
  },
  {
    value: 'cauca',
    label: 'Cauca - Cauca',
  },
  {
    value: 'cauca-almaguer',
    label: 'Almaguer - Cauca',
  },
  {
    value: 'cauca-argelia',
    label: 'Argelia - Cauca',
  },
  {
    value: 'cauca-balboa',
    label: 'Balboa - Cauca',
  },
  {
    value: 'cauca-bolivar',
    label: 'Bolívar - Cauca',
  },
  {
    value: 'cauca-buenosaires',
    label: 'Buenos Aires - Cauca',
  },
  {
    value: 'cauca-cajibio',
    label: 'Cajibío - Cauca',
  },
  {
    value: 'cauca-caldono',
    label: 'Caldono - Cauca',
  },
  {
    value: 'cauca-caloto',
    label: 'Caloto - Cauca',
  },
  {
    value: 'cauca-corinto',
    label: 'Corinto - Cauca',
  },
  {
    value: 'cauca-eltambo',
    label: 'El Tambo - Cauca',
  },
  {
    value: 'cauca-florencia',
    label: 'Florencia - Cauca',
  },
  {
    value: 'cauca-guachene',
    label: 'Guachené - Cauca',
  },
  {
    value: 'cauca-guapi',
    label: 'Guapí - Cauca',
  },
  {
    value: 'cauca-inza',
    label: 'Inzá - Cauca',
  },
  {
    value: 'cauca-jambalo',
    label: 'Jambaló - Cauca',
  },
  {
    value: 'cauca-lasierra',
    label: 'La Sierra - Cauca',
  },
  {
    value: 'cauca-lavega',
    label: 'La Vega - Cauca',
  },
  {
    value: 'cauca-lopezdemicay',
    label: 'López De Micay - Cauca',
  },
  {
    value: 'cauca-mercaderes',
    label: 'Mercaderes - Cauca',
  },
  {
    value: 'cauca-miranda',
    label: 'Miranda - Cauca',
  },
  {
    value: 'cauca-morales',
    label: 'Morales - Cauca',
  },
  {
    value: 'cauca-padilla',
    label: 'Padilla - Cauca',
  },
  {
    value: 'cauca-patia',
    label: 'Patía - Cauca',
  },
  {
    value: 'cauca-piamonte',
    label: 'Piamonte - Cauca',
  },
  {
    value: 'cauca-piendamo',
    label: 'Piendamó - Cauca',
  },
  {
    value: 'cauca-popayan',
    label: 'Popayán - Cauca',
  },
  {
    value: 'cauca-puertotejada',
    label: 'Puerto Tejada - Cauca',
  },
  {
    value: 'cauca-purace',
    label: 'Puracé - Cauca',
  },
  {
    value: 'cauca-paez',
    label: 'Páez - Cauca',
  },
  {
    value: 'cauca-rosas',
    label: 'Rosas - Cauca',
  },
  {
    value: 'cauca-sansebastian',
    label: 'San Sebastián - Cauca',
  },
  {
    value: 'cauca-santarosa',
    label: 'Santa Rosa - Cauca',
  },
  {
    value: 'cauca-santanderdequilichao',
    label: 'Santander De Quilichao - Cauca',
  },
  {
    value: 'cauca-silvia',
    label: 'Silvia - Cauca',
  },
  {
    value: 'cauca-sotara',
    label: 'Sotara - Cauca',
  },
  {
    value: 'cauca-sucre',
    label: 'Sucre - Cauca',
  },
  {
    value: 'cauca-suarez',
    label: 'Suárez - Cauca',
  },
  {
    value: 'cauca-timbiqui',
    label: 'Timbiquí - Cauca',
  },
  {
    value: 'cauca-timbio',
    label: 'Timbío - Cauca',
  },
  {
    value: 'cauca-toribio',
    label: 'Toribío - Cauca',
  },
  {
    value: 'cauca-totoro',
    label: 'Totoró - Cauca',
  },
  {
    value: 'cauca-villarica',
    label: 'Villa Rica - Cauca',
  },
  {
    value: 'cesar',
    label: 'Cesar - Cesar',
  },
  {
    value: 'cesar-aguachica',
    label: 'Aguachica - Cesar',
  },
  {
    value: 'cesar-agustincodazzi',
    label: 'Agustín Codazzi - Cesar',
  },
  {
    value: 'cesar-astrea',
    label: 'Astrea - Cesar',
  },
  {
    value: 'cesar-becerril',
    label: 'Becerril - Cesar',
  },
  {
    value: 'cesar-bosconia',
    label: 'Bosconia - Cesar',
  },
  {
    value: 'cesar-chimichagua',
    label: 'Chimichagua - Cesar',
  },
  {
    value: 'cesar-chiriguana',
    label: 'Chiriguaná - Cesar',
  },
  {
    value: 'cesar-curumani',
    label: 'Curumaní - Cesar',
  },
  {
    value: 'cesar-elcopey',
    label: 'El Copey - Cesar',
  },
  {
    value: 'cesar-elpaso',
    label: 'El Paso - Cesar',
  },
  {
    value: 'cesar-gamarra',
    label: 'Gamarra - Cesar',
  },
  {
    value: 'cesar-gonzalez',
    label: 'González - Cesar',
  },
  {
    value: 'cesar-lagloria',
    label: 'La Gloria - Cesar',
  },
  {
    value: 'cesar-lajaguadeibirico',
    label: 'La Jagua De Ibirico - Cesar',
  },
  {
    value: 'cesar-lapaz',
    label: 'La Paz - Cesar',
  },
  {
    value: 'cesar-manaurebalcondelcesar',
    label: 'Manaure Balcón Del Cesar - Cesar',
  },
  {
    value: 'cesar-pailitas',
    label: 'Pailitas - Cesar',
  },
  {
    value: 'cesar-pelaya',
    label: 'Pelaya - Cesar',
  },
  {
    value: 'cesar-pueblobello',
    label: 'Pueblo Bello - Cesar',
  },
  {
    value: 'cesar-riodeoro',
    label: 'Río De Oro - Cesar',
  },
  {
    value: 'cesar-sanalberto',
    label: 'San Alberto - Cesar',
  },
  {
    value: 'cesar-sandiego',
    label: 'San Diego - Cesar',
  },
  {
    value: 'cesar-sanmartin',
    label: 'San Martín - Cesar',
  },
  {
    value: 'cesar-tamalameque',
    label: 'Tamalameque - Cesar',
  },
  {
    value: 'cesar-valledupar',
    label: 'Valledupar - Cesar',
  },
  {
    value: 'cordoba',
    label: 'Córdoba - Córdoba',
  },
  {
    value: 'cordoba-ayapel',
    label: 'Ayapel - Córdoba',
  },
  {
    value: 'cordoba-buenavista',
    label: 'Buenavista - Córdoba',
  },
  {
    value: 'cordoba-canalete',
    label: 'Canalete - Córdoba',
  },
  {
    value: 'cordoba-cerete',
    label: 'Cereté - Córdoba',
  },
  {
    value: 'cordoba-chima',
    label: 'Chimá - Córdoba',
  },
  {
    value: 'cordoba-chinu',
    label: 'Chinú - Córdoba',
  },
  {
    value: 'cordoba-cienagadeoro',
    label: 'Ciénaga De Oro - Córdoba',
  },
  {
    value: 'cordoba-cotorra',
    label: 'Cotorra - Córdoba',
  },
  {
    value: 'cordoba-laapartada',
    label: 'La Apartada - Córdoba',
  },
  {
    value: 'cordoba-lorica',
    label: 'Lorica - Córdoba',
  },
  {
    value: 'cordoba-loscordobas',
    label: 'Los Córdobas - Córdoba',
  },
  {
    value: 'cordoba-momil',
    label: 'Momil - Córdoba',
  },
  {
    value: 'cordoba-montelibano',
    label: 'Montelíbano - Córdoba',
  },
  {
    value: 'cordoba-monteria',
    label: 'Montería - Córdoba',
  },
  {
    value: 'cordoba-monitos',
    label: 'Moñitos - Córdoba',
  },
  {
    value: 'cordoba-planetarica',
    label: 'Planeta Rica - Córdoba',
  },
  {
    value: 'cordoba-pueblonuevo',
    label: 'Pueblo Nuevo - Córdoba',
  },
  {
    value: 'cordoba-puertoescondido',
    label: 'Puerto Escondido - Córdoba',
  },
  {
    value: 'cordoba-puertolibertador',
    label: 'Puerto Libertador - Córdoba',
  },
  {
    value: 'cordoba-purisimadelaconcepcion',
    label: 'Purísima De La Concepción - Córdoba',
  },
  {
    value: 'cordoba-sahagun',
    label: 'Sahagún - Córdoba',
  },
  {
    value: 'cordoba-sanandresdesotavento',
    label: 'San Andrés De Sotavento - Córdoba',
  },
  {
    value: 'cordoba-sanantero',
    label: 'San Antero - Córdoba',
  },
  {
    value: 'cordoba-sanbernardodelviento',
    label: 'San Bernardo Del Viento - Córdoba',
  },
  {
    value: 'cordoba-sancarlos',
    label: 'San Carlos - Córdoba',
  },
  {
    value: 'cordoba-sanjosedeure',
    label: 'San José De Uré - Córdoba',
  },
  {
    value: 'cordoba-sanpelayo',
    label: 'San Pelayo - Córdoba',
  },
  {
    value: 'cordoba-tierralta',
    label: 'Tierralta - Córdoba',
  },
  {
    value: 'cordoba-tuchin',
    label: 'Tuchín - Córdoba',
  },
  {
    value: 'cordoba-valencia',
    label: 'Valencia - Córdoba',
  },
  {
    value: 'cundinamarca',
    label: 'Cundinamarca - Cundinamarca',
  },
  {
    value: 'cundinamarca-aguadedios',
    label: 'Agua De Dios - Cundinamarca',
  },
  {
    value: 'cundinamarca-alban',
    label: 'Albán - Cundinamarca',
  },
  {
    value: 'cundinamarca-anapoima',
    label: 'Anapoima - Cundinamarca',
  },
  {
    value: 'cundinamarca-anolaima',
    label: 'Anolaima - Cundinamarca',
  },
  {
    value: 'cundinamarca-apulo',
    label: 'Apulo - Cundinamarca',
  },
  {
    value: 'cundinamarca-arbelaez',
    label: 'Arbeláez - Cundinamarca',
  },
  {
    value: 'cundinamarca-beltran',
    label: 'Beltrán - Cundinamarca',
  },
  {
    value: 'cundinamarca-bituima',
    label: 'Bituima - Cundinamarca',
  },
  {
    value: 'cundinamarca-bojaca',
    label: 'Bojacá - Cundinamarca',
  },
  {
    value: 'cundinamarca-cabrera',
    label: 'Cabrera - Cundinamarca',
  },
  {
    value: 'cundinamarca-cachipay',
    label: 'Cachipay - Cundinamarca',
  },
  {
    value: 'cundinamarca-cajica',
    label: 'Cajicá - Cundinamarca',
  },
  {
    value: 'cundinamarca-caparrapi',
    label: 'Caparrapí - Cundinamarca',
  },
  {
    value: 'cundinamarca-carmendecarupa',
    label: 'Carmen De Carupa - Cundinamarca',
  },
  {
    value: 'cundinamarca-chaguani',
    label: 'Chaguaní - Cundinamarca',
  },
  {
    value: 'cundinamarca-chipaque',
    label: 'Chipaque - Cundinamarca',
  },
  {
    value: 'cundinamarca-choachi',
    label: 'Choachí - Cundinamarca',
  },
  {
    value: 'cundinamarca-choconta',
    label: 'Chocontá - Cundinamarca',
  },
  {
    value: 'cundinamarca-chia',
    label: 'Chía - Cundinamarca',
  },
  {
    value: 'cundinamarca-cogua',
    label: 'Cogua - Cundinamarca',
  },
  {
    value: 'cundinamarca-cota',
    label: 'Cota - Cundinamarca',
  },
  {
    value: 'cundinamarca-cucunuba',
    label: 'Cucunubá - Cundinamarca',
  },
  {
    value: 'cundinamarca-caqueza',
    label: 'Cáqueza - Cundinamarca',
  },
  {
    value: 'cundinamarca-elcolegio',
    label: 'El Colegio - Cundinamarca',
  },
  {
    value: 'cundinamarca-elpenon',
    label: 'El Peñón - Cundinamarca',
  },
  {
    value: 'cundinamarca-elrosal',
    label: 'El Rosal - Cundinamarca',
  },
  {
    value: 'cundinamarca-facatativa',
    label: 'Facatativá - Cundinamarca',
  },
  {
    value: 'cundinamarca-fosca',
    label: 'Fosca - Cundinamarca',
  },
  {
    value: 'cundinamarca-funza',
    label: 'Funza - Cundinamarca',
  },
  {
    value: 'cundinamarca-fusagasuga',
    label: 'Fusagasugá - Cundinamarca',
  },
  {
    value: 'cundinamarca-fomeque',
    label: 'Fómeque - Cundinamarca',
  },
  {
    value: 'cundinamarca-fuquene',
    label: 'Fúquene - Cundinamarca',
  },
  {
    value: 'cundinamarca-gachala',
    label: 'Gachalá - Cundinamarca',
  },
  {
    value: 'cundinamarca-gachancipa',
    label: 'Gachancipá - Cundinamarca',
  },
  {
    value: 'cundinamarca-gacheta',
    label: 'Gachetá - Cundinamarca',
  },
  {
    value: 'cundinamarca-gama',
    label: 'Gama - Cundinamarca',
  },
  {
    value: 'cundinamarca-girardot',
    label: 'Girardot - Cundinamarca',
  },
  {
    value: 'cundinamarca-granada',
    label: 'Granada - Cundinamarca',
  },
  {
    value: 'cundinamarca-guacheta',
    label: 'Guachetá - Cundinamarca',
  },
  {
    value: 'cundinamarca-guaduas',
    label: 'Guaduas - Cundinamarca',
  },
  {
    value: 'cundinamarca-guasca',
    label: 'Guasca - Cundinamarca',
  },
  {
    value: 'cundinamarca-guataqui',
    label: 'Guataquí - Cundinamarca',
  },
  {
    value: 'cundinamarca-guatavita',
    label: 'Guatavita - Cundinamarca',
  },
  {
    value: 'cundinamarca-guayabaldesiquima',
    label: 'Guayabal De Síquima - Cundinamarca',
  },
  {
    value: 'cundinamarca-guayabetal',
    label: 'Guayabetal - Cundinamarca',
  },
  {
    value: 'cundinamarca-gutierrez',
    label: 'Gutiérrez - Cundinamarca',
  },
  {
    value: 'cundinamarca-jerusalen',
    label: 'Jerusalén - Cundinamarca',
  },
  {
    value: 'cundinamarca-junin',
    label: 'Junín - Cundinamarca',
  },
  {
    value: 'cundinamarca-lacalera',
    label: 'La Calera - Cundinamarca',
  },
  {
    value: 'cundinamarca-lamesa',
    label: 'La Mesa - Cundinamarca',
  },
  {
    value: 'cundinamarca-lapalma',
    label: 'La Palma - Cundinamarca',
  },
  {
    value: 'cundinamarca-lapena',
    label: 'La Peña - Cundinamarca',
  },
  {
    value: 'cundinamarca-lavega',
    label: 'La Vega - Cundinamarca',
  },
  {
    value: 'cundinamarca-lenguazaque',
    label: 'Lenguazaque - Cundinamarca',
  },
  {
    value: 'cundinamarca-macheta',
    label: 'Machetá - Cundinamarca',
  },
  {
    value: 'cundinamarca-madrid',
    label: 'Madrid - Cundinamarca',
  },
  {
    value: 'cundinamarca-manta',
    label: 'Manta - Cundinamarca',
  },
  {
    value: 'cundinamarca-medina',
    label: 'Medina - Cundinamarca',
  },
  {
    value: 'cundinamarca-mosquera',
    label: 'Mosquera - Cundinamarca',
  },
  {
    value: 'cundinamarca-narino',
    label: 'Nariño - Cundinamarca - Nariño',
  },
  {
    value: 'cundinamarca-nemocon',
    label: 'Nemocón - Cundinamarca',
  },
  {
    value: 'cundinamarca-nilo',
    label: 'Nilo - Cundinamarca',
  },
  {
    value: 'cundinamarca-nimaima',
    label: 'Nimaima - Cundinamarca',
  },
  {
    value: 'cundinamarca-nocaima',
    label: 'Nocaima - Cundinamarca',
  },
  {
    value: 'cundinamarca-pacho',
    label: 'Pacho - Cundinamarca',
  },
  {
    value: 'cundinamarca-paime',
    label: 'Paime - Cundinamarca',
  },
  {
    value: 'cundinamarca-pandi',
    label: 'Pandi - Cundinamarca',
  },
  {
    value: 'cundinamarca-paratebueno',
    label: 'Paratebueno - Cundinamarca',
  },
  {
    value: 'cundinamarca-pasca',
    label: 'Pasca - Cundinamarca',
  },
  {
    value: 'cundinamarca-puertosalgar',
    label: 'Puerto Salgar - Cundinamarca',
  },
  {
    value: 'cundinamarca-puli',
    label: 'Pulí - Cundinamarca',
  },
  {
    value: 'cundinamarca-quebradanegra',
    label: 'Quebradanegra - Cundinamarca',
  },
  {
    value: 'cundinamarca-quetame',
    label: 'Quetame - Cundinamarca',
  },
  {
    value: 'cundinamarca-quipile',
    label: 'Quipile - Cundinamarca',
  },
  {
    value: 'cundinamarca-ricaurte',
    label: 'Ricaurte - Cundinamarca',
  },
  {
    value: 'cundinamarca-sanantoniodeltequendama',
    label: 'San Antonio Del Tequendama - Cundinamarca',
  },
  {
    value: 'cundinamarca-sanbernardo',
    label: 'San Bernardo - Cundinamarca',
  },
  {
    value: 'cundinamarca-sancayetano',
    label: 'San Cayetano - Cundinamarca',
  },
  {
    value: 'cundinamarca-sanfrancisco',
    label: 'San Francisco - Cundinamarca',
  },
  {
    value: 'cundinamarca-sanjuanderioseco',
    label: 'San Juan De Rioseco - Cundinamarca',
  },
  {
    value: 'cundinamarca-sasaima',
    label: 'Sasaima - Cundinamarca',
  },
  {
    value: 'cundinamarca-sesquile',
    label: 'Sesquilé - Cundinamarca',
  },
  {
    value: 'cundinamarca-sibate',
    label: 'Sibaté - Cundinamarca',
  },
  {
    value: 'cundinamarca-silvania',
    label: 'Silvania - Cundinamarca',
  },
  {
    value: 'cundinamarca-simijaca',
    label: 'Simijaca - Cundinamarca',
  },
  {
    value: 'cundinamarca-soacha',
    label: 'Soacha - Cundinamarca',
  },
  {
    value: 'cundinamarca-sopo',
    label: 'Sopó - Cundinamarca',
  },
  {
    value: 'cundinamarca-subachoque',
    label: 'Subachoque - Cundinamarca',
  },
  {
    value: 'cundinamarca-suesca',
    label: 'Suesca - Cundinamarca',
  },
  {
    value: 'cundinamarca-supata',
    label: 'Supatá - Cundinamarca',
  },
  {
    value: 'cundinamarca-susa',
    label: 'Susa - Cundinamarca',
  },
  {
    value: 'cundinamarca-sutatausa',
    label: 'Sutatausa - Cundinamarca',
  },
  {
    value: 'cundinamarca-tabio',
    label: 'Tabio - Cundinamarca',
  },
  {
    value: 'cundinamarca-tausa',
    label: 'Tausa - Cundinamarca',
  },
  {
    value: 'cundinamarca-tena',
    label: 'Tena - Cundinamarca',
  },
  {
    value: 'cundinamarca-tenjo',
    label: 'Tenjo - Cundinamarca',
  },
  {
    value: 'cundinamarca-tibacuy',
    label: 'Tibacuy - Cundinamarca',
  },
  {
    value: 'cundinamarca-tibirita',
    label: 'Tibirita - Cundinamarca',
  },
  {
    value: 'cundinamarca-tocaima',
    label: 'Tocaima - Cundinamarca',
  },
  {
    value: 'cundinamarca-tocancipa',
    label: 'Tocancipá - Cundinamarca',
  },
  {
    value: 'cundinamarca-topaipi',
    label: 'Topaipí - Cundinamarca',
  },
  {
    value: 'cundinamarca-ubala',
    label: 'Ubalá - Cundinamarca',
  },
  {
    value: 'cundinamarca-ubaque',
    label: 'Ubaque - Cundinamarca',
  },
  {
    value: 'cundinamarca-une',
    label: 'Une - Cundinamarca',
  },
  {
    value: 'cundinamarca-venecia',
    label: 'Venecia - Cundinamarca',
  },
  {
    value: 'cundinamarca-vergara',
    label: 'Vergara - Cundinamarca',
  },
  {
    value: 'cundinamarca-viani',
    label: 'Vianí - Cundinamarca',
  },
  {
    value: 'cundinamarca-villadesandiego de ubate',
    label: 'Villa De San Diego De Ubaté - Cundinamarca',
  },
  {
    value: 'cundinamarca-villagomez',
    label: 'Villagómez - Cundinamarca',
  },
  {
    value: 'cundinamarca-villapinzon',
    label: 'Villapinzón - Cundinamarca',
  },
  {
    value: 'cundinamarca-villeta',
    label: 'Villeta - Cundinamarca',
  },
  {
    value: 'cundinamarca-viota',
    label: 'Viotá - Cundinamarca',
  },
  {
    value: 'cundinamarca-yacopi',
    label: 'Yacopí - Cundinamarca',
  },
  {
    value: 'cundinamarca-zipacon',
    label: 'Zipacón - Cundinamarca',
  },
  {
    value: 'cundinamarca-zipaquira',
    label: 'Zipaquirá - Cundinamarca',
  },
  {
    value: 'cundinamarca-utica',
    label: 'Útica - Cundinamarca',
  },
  {
    value: 'choco',
    label: 'Chocó - Chocó',
  },
  {
    value: 'choco-acandi',
    label: 'Acandí - Chocó',
  },
  {
    value: 'choco-altobaudo',
    label: 'Alto Baudó - Chocó',
  },
  {
    value: 'choco-atrato',
    label: 'Atrato - Chocó',
  },
  {
    value: 'choco-bagado',
    label: 'Bagadó - Chocó',
  },
  {
    value: 'choco-bahiasolano',
    label: 'Bahía Solano - Chocó',
  },
  {
    value: 'choco-bajobaudo',
    label: 'Bajo Baudó - Chocó',
  },
  {
    value: 'choco-bojaya',
    label: 'Bojayá - Chocó',
  },
  {
    value: 'choco-carmendeldarien',
    label: 'Carmen Del Darién - Chocó',
  },
  {
    value: 'choco-condoto',
    label: 'Condoto - Chocó',
  },
  {
    value: 'choco-certegui',
    label: 'Cértegui - Chocó',
  },
  {
    value: 'choco-elcantondelsan pablo',
    label: 'El Cantón Del San Pablo - Chocó',
  },
  {
    value: 'choco-elcarmendeatrato',
    label: 'El Carmen De Atrato - Chocó',
  },
  {
    value: 'choco-ellitoraldelsan juan',
    label: 'El Litoral Del San Juan - Chocó',
  },
  {
    value: 'choco-istmina',
    label: 'Istmina - Chocó',
  },
  {
    value: 'choco-jurado',
    label: 'Juradó - Chocó',
  },
  {
    value: 'choco-lloro',
    label: 'Lloró - Chocó',
  },
  {
    value: 'choco-medioatrato',
    label: 'Medio Atrato - Chocó',
  },
  {
    value: 'choco-mediobaudo',
    label: 'Medio Baudó - Chocó',
  },
  {
    value: 'choco-mediosanjuan',
    label: 'Medio San Juan - Chocó',
  },
  {
    value: 'choco-nuqui',
    label: 'Nuquí - Chocó',
  },
  {
    value: 'choco-novita',
    label: 'Nóvita - Chocó',
  },
  {
    value: 'choco-quibdo',
    label: 'Quibdó - Chocó',
  },
  {
    value: 'choco-riosucio',
    label: 'Riosucio - Chocó',
  },
  {
    value: 'choco-rioiro',
    label: 'Río Iró - Chocó',
  },
  {
    value: 'choco-rioquito',
    label: 'Río Quito - Chocó',
  },
  {
    value: 'choco-sanjosedelpalmar',
    label: 'San José Del Palmar - Chocó',
  },
  {
    value: 'choco-sipi',
    label: 'Sipí - Chocó',
  },
  {
    value: 'choco-tado',
    label: 'Tadó - Chocó',
  },
  {
    value: 'choco-unguia',
    label: 'Unguía - Chocó',
  },
  {
    value: 'choco-unionpanamericana',
    label: 'Unión Panamericana - Chocó',
  },
  {
    value: 'huila',
    label: 'Huila',
  },
  {
    value: 'huila-acevedo',
    label: 'Acevedo - Huila',
  },
  {
    value: 'huila-agrado',
    label: 'Agrado - Huila',
  },
  {
    value: 'huila-aipe',
    label: 'Aipe - Huila',
  },
  {
    value: 'huila-algeciras',
    label: 'Algeciras - Huila',
  },
  {
    value: 'huila-altamira',
    label: 'Altamira - Huila',
  },
  {
    value: 'huila-baraya',
    label: 'Baraya - Huila',
  },
  {
    value: 'huila-campoalegre',
    label: 'Campoalegre - Huila',
  },
  {
    value: 'huila-colombia',
    label: 'Colombia - Huila',
  },
  {
    value: 'huila-elias',
    label: 'Elías - Huila',
  },
  {
    value: 'huila-garzon',
    label: 'Garzón - Huila',
  },
  {
    value: 'huila-gigante',
    label: 'Gigante - Huila',
  },
  {
    value: 'huila-guadalupe',
    label: 'Guadalupe - Huila',
  },
  {
    value: 'huila-hobo',
    label: 'Hobo - Huila',
  },
  {
    value: 'huila-isnos',
    label: 'Isnos - Huila',
  },
  {
    value: 'huila-laargentina',
    label: 'La Argentina - Huila',
  },
  {
    value: 'huila-laplata',
    label: 'La Plata - Huila',
  },
  {
    value: 'huila-neiva',
    label: 'Neiva - Huila',
  },
  {
    value: 'huila-nataga',
    label: 'Nátaga - Huila',
  },
  {
    value: 'huila-oporapa',
    label: 'Oporapa - Huila',
  },
  {
    value: 'huila-paicol',
    label: 'Paicol - Huila',
  },
  {
    value: 'huila-palermo',
    label: 'Palermo - Huila',
  },
  {
    value: 'huila-palestina',
    label: 'Palestina - Huila',
  },
  {
    value: 'huila-pital',
    label: 'Pital - Huila',
  },
  {
    value: 'huila-pitalito',
    label: 'Pitalito - Huila',
  },
  {
    value: 'huila-rivera',
    label: 'Rivera - Huila',
  },
  {
    value: 'huila-saladoblanco',
    label: 'Saladoblanco - Huila',
  },
  {
    value: 'huila-sanagustin',
    label: 'San Agustín - Huila',
  },
  {
    value: 'huila-santamaria',
    label: 'Santa María - Huila',
  },
  {
    value: 'huila-suaza',
    label: 'Suaza - Huila',
  },
  {
    value: 'huila-tarqui',
    label: 'Tarqui - Huila',
  },
  {
    value: 'huila-tello',
    label: 'Tello - Huila',
  },
  {
    value: 'huila-teruel',
    label: 'Teruel - Huila',
  },
  {
    value: 'huila-tesalia',
    label: 'Tesalia - Huila',
  },
  {
    value: 'huila-timana',
    label: 'Timaná - Huila',
  },
  {
    value: 'huila-villavieja',
    label: 'Villavieja - Huila',
  },
  {
    value: 'huila-yaguara',
    label: 'Yaguará - Huila',
  },
  {
    value: 'huila-iquira',
    label: 'Íquira - Huila',
  },
  {
    value: 'laguajira',
    label: 'La Guajira',
  },
  {
    value: 'laguajira-albania',
    label: 'Albania - La Guajira',
  },
  {
    value: 'laguajira-barrancas',
    label: 'Barrancas - La Guajira',
  },
  {
    value: 'laguajira-dibulla',
    label: 'Dibulla - La Guajira',
  },
  {
    value: 'laguajira-distraccion',
    label: 'Distracción - La Guajira',
  },
  {
    value: 'laguajira-elmolino',
    label: 'El Molino - La Guajira',
  },
  {
    value: 'laguajira-fonseca',
    label: 'Fonseca - La Guajira',
  },
  {
    value: 'laguajira-hatonuevo',
    label: 'Hatonuevo - La Guajira',
  },
  {
    value: 'laguajira-lajaguadelpilar',
    label: 'La Jagua Del Pilar - La Guajira',
  },
  {
    value: 'laguajira-maicao',
    label: 'Maicao - La Guajira',
  },
  {
    value: 'laguajira-manaure',
    label: 'Manaure - La Guajira',
  },
  {
    value: 'laguajira-riohacha',
    label: 'Riohacha - La Guajira',
  },
  {
    value: 'laguajira-sanjuandelcesar',
    label: 'San Juan Del Cesar - La Guajira',
  },
  {
    value: 'laguajira-uribia',
    label: 'Uribia - La Guajira',
  },
  {
    value: 'laguajira-urumita',
    label: 'Urumita - La Guajira',
  },
  {
    value: 'laguajira-villanueva',
    label: 'Villanueva - La Guajira',
  },
  {
    value: 'magdalena',
    label: 'Magdalena - Magdalena',
  },
  {
    value: 'magdalena-algarrobo',
    label: 'Algarrobo - Magdalena',
  },
  {
    value: 'magdalena-aracataca',
    label: 'Aracataca - Magdalena',
  },
  {
    value: 'magdalena-ariguani',
    label: 'Ariguaní - Magdalena',
  },
  {
    value: 'magdalena-cerrodesanantonio',
    label: 'Cerro De San Antonio - Magdalena',
  },
  {
    value: 'magdalena-chivolo',
    label: 'Chivolo - Magdalena',
  },
  {
    value: 'magdalena-cienaga',
    label: 'Ciénaga - Magdalena',
  },
  {
    value: 'magdalena-concordia',
    label: 'Concordia - Magdalena',
  },
  {
    value: 'magdalena-elbanco',
    label: 'El Banco - Magdalena',
  },
  {
    value: 'magdalena-elpinon',
    label: 'El Piñón - Magdalena',
  },
  {
    value: 'magdalena-elreten',
    label: 'El Retén - Magdalena',
  },
  {
    value: 'magdalena-fundacion',
    label: 'Fundación - Magdalena',
  },
  {
    value: 'magdalena-guamal',
    label: 'Guamal - Magdalena',
  },
  {
    value: 'magdalena-nuevagranada',
    label: 'Nueva Granada - Magdalena',
  },
  {
    value: 'magdalena-pedraza',
    label: 'Pedraza - Magdalena',
  },
  {
    value: 'magdalena-pijinodelcarmen',
    label: 'Pijiño Del Carmen - Magdalena',
  },
  {
    value: 'magdalena-pivijay',
    label: 'Pivijay - Magdalena',
  },
  {
    value: 'magdalena-plato',
    label: 'Plato - Magdalena',
  },
  {
    value: 'magdalena-puebloviejo',
    label: 'Puebloviejo - Magdalena',
  },
  {
    value: 'magdalena-remolino',
    label: 'Remolino - Magdalena',
  },
  {
    value: 'magdalena-sabanasdesanangel',
    label: 'Sabanas De San Ángel - Magdalena',
  },
  {
    value: 'magdalena-salamina',
    label: 'Salamina - Magdalena',
  },
  {
    value: 'magdalena-sansebastiandebuenavista',
    label: 'San Sebastián De Buenavista - Magdalena',
  },
  {
    value: 'magdalena-sanzenon',
    label: 'San Zenón - Magdalena',
  },
  {
    value: 'magdalena-santaana',
    label: 'Santa Ana - Magdalena',
  },
  {
    value: 'magdalena-santabarbaradepinto',
    label: 'Santa Bárbara De Pinto - Magdalena',
  },
  {
    value: 'magdalena-santamarta',
    label: 'Santa Marta - Magdalena',
  },
  {
    value: 'magdalena-sitionuevo',
    label: 'Sitionuevo - Magdalena',
  },
  {
    value: 'magdalena-tenerife',
    label: 'Tenerife - Magdalena',
  },
  {
    value: 'magdalena-zapayan',
    label: 'Zapayán - Magdalena',
  },
  {
    value: 'magdalena-zonabananera',
    label: 'Zona Bananera - Magdalena',
  },
  {
    value: 'meta',
    label: 'Meta',
  },
  {
    value: 'meta-acacias',
    label: 'Acacías - Meta',
  },
  {
    value: 'meta-barrancadeupia',
    label: 'Barranca De Upía - Meta',
  },
  {
    value: 'meta-cabuyaro',
    label: 'Cabuyaro - Meta',
  },
  {
    value: 'meta-castillalanueva',
    label: 'Castilla La Nueva - Meta',
  },
  {
    value: 'meta-cubarral',
    label: 'Cubarral - Meta',
  },
  {
    value: 'meta-cumaral',
    label: 'Cumaral - Meta',
  },
  {
    value: 'meta-elcalvario',
    label: 'El Calvario - Meta',
  },
  {
    value: 'meta-elcastillo',
    label: 'El Castillo - Meta',
  },
  {
    value: 'meta-eldorado',
    label: 'El Dorado - Meta',
  },
  {
    value: 'meta-fuentedeoro',
    label: 'Fuente De Oro - Meta',
  },
  {
    value: 'meta-granada',
    label: 'Granada - Meta',
  },
  {
    value: 'meta-guamal',
    label: 'Guamal - Meta',
  },
  {
    value: 'meta-lamacarena',
    label: 'La Macarena - Meta',
  },
  {
    value: 'meta-lejanias',
    label: 'Lejanías - Meta',
  },
  {
    value: 'meta-mapiripan',
    label: 'Mapiripán - Meta',
  },
  {
    value: 'meta-mesetas',
    label: 'Mesetas - Meta',
  },
  {
    value: 'meta-puertoconcordia',
    label: 'Puerto Concordia - Meta',
  },
  {
    value: 'meta-puertogaitan',
    label: 'Puerto Gaitán - Meta',
  },
  {
    value: 'meta-puertolleras',
    label: 'Puerto Lleras - Meta',
  },
  {
    value: 'meta-puertolopez',
    label: 'Puerto López - Meta',
  },
  {
    value: 'meta-puertorico',
    label: 'Puerto Rico - Meta',
  },
  {
    value: 'meta-restrepo',
    label: 'Restrepo - Meta',
  },
  {
    value: 'meta-sancarlosdeguaroa',
    label: 'San Carlos De Guaroa - Meta',
  },
  {
    value: 'meta-sanjuandearama',
    label: 'San Juan De Arama - Meta',
  },
  {
    value: 'meta-sanjuanito',
    label: 'San Juanito - Meta',
  },
  {
    value: 'meta-sanmartin',
    label: 'San Martín - Meta',
  },
  {
    value: 'meta-uribe',
    label: 'Uribe - Meta',
  },
  {
    value: 'meta-villavicencio',
    label: 'Villavicencio - Meta',
  },
  {
    value: 'meta-vistahermosa',
    label: 'Vistahermosa - Meta',
  },
  {
    value: 'narino',
    label: 'Nariño - Nariño',
  },
  {
    value: 'narino-alban',
    label: 'Albán - Nariño',
  },
  {
    value: 'narino-aldana',
    label: 'Aldana - Nariño',
  },
  {
    value: 'narino-ancuya',
    label: 'Ancuyá - Nariño',
  },
  {
    value: 'narino-arboleda',
    label: 'Arboleda - Nariño',
  },
  {
    value: 'narino-barbacoas',
    label: 'Barbacoas - Nariño',
  },
  {
    value: 'narino-belen',
    label: 'Belén - Nariño',
  },
  {
    value: 'narino-buesaco',
    label: 'Buesaco - Nariño',
  },
  {
    value: 'narino-chachagui',
    label: 'Chachagüí - Nariño',
  },
  {
    value: 'narino-colon',
    label: 'Colón - Nariño',
  },
  {
    value: 'narino-consaca',
    label: 'Consacá - Nariño',
  },
  {
    value: 'narino-contadero',
    label: 'Contadero - Nariño',
  },
  {
    value: 'narino-cuaspud',
    label: 'Cuaspúd - Nariño',
  },
  {
    value: 'narino-cumbal',
    label: 'Cumbal - Nariño',
  },
  {
    value: 'narino-cumbitara',
    label: 'Cumbitara - Nariño',
  },
  {
    value: 'narino-cordoba',
    label: 'Córdoba - Nariño',
  },
  {
    value: 'narino-elcharco',
    label: 'El Charco - Nariño',
  },
  {
    value: 'narino-elpenol',
    label: 'El Peñol - Nariño',
  },
  {
    value: 'narino-elrosario',
    label: 'El Rosario - Nariño',
  },
  {
    value: 'narino-eltablondegomez',
    label: 'El Tablón De Gómez - Nariño',
  },
  {
    value: 'narino-eltambo',
    label: 'El Tambo - Nariño',
  },
  {
    value: 'narino-franciscopizarro',
    label: 'Francisco Pizarro - Nariño',
  },
  {
    value: 'narino-funes',
    label: 'Funes - Nariño',
  },
  {
    value: 'narino-guachucal',
    label: 'Guachucal - Nariño',
  },
  {
    value: 'narino-guaitarilla',
    label: 'Guaitarilla - Nariño',
  },
  {
    value: 'narino-gualmatan',
    label: 'Gualmatán - Nariño',
  },
  {
    value: 'narino-iles',
    label: 'Iles - Nariño',
  },
  {
    value: 'narino-imues',
    label: 'Imués - Nariño',
  },
  {
    value: 'narino-ipiales',
    label: 'Ipiales - Nariño',
  },
  {
    value: 'narino-lacruz',
    label: 'La Cruz - Nariño',
  },
  {
    value: 'narino-laflorida',
    label: 'La Florida - Nariño',
  },
  {
    value: 'narino-lallanada',
    label: 'La Llanada - Nariño',
  },
  {
    value: 'narino-latola',
    label: 'La Tola - Nariño',
  },
  {
    value: 'narino-launion',
    label: 'La Unión - Nariño',
  },
  {
    value: 'narino-leiva',
    label: 'Leiva - Nariño',
  },
  {
    value: 'narino-linares',
    label: 'Linares - Nariño',
  },
  {
    value: 'narino-losandes',
    label: 'Los Andes - Nariño',
  },
  {
    value: 'narino-magui',
    label: 'Magüí - Nariño',
  },
  {
    value: 'narino-mallama',
    label: 'Mallama - Nariño',
  },
  {
    value: 'narino-mosquera',
    label: 'Mosquera - Nariño',
  },
  {
    value: 'narino-narino',
    label: 'Nariño - Nariño',
  },
  {
    value: 'narino-olayaherrera',
    label: 'Olaya Herrera - Nariño',
  },
  {
    value: 'narino-ospina',
    label: 'Ospina - Nariño',
  },
  {
    value: 'narino-pasto',
    label: 'Pasto - Nariño',
  },
  {
    value: 'narino-policarpa',
    label: 'Policarpa - Nariño',
  },
  {
    value: 'narino-potosi',
    label: 'Potosí - Nariño',
  },
  {
    value: 'narino-providencia',
    label: 'Providencia - Nariño',
  },
  {
    value: 'narino-puerres',
    label: 'Puerres - Nariño',
  },
  {
    value: 'narino-pupiales',
    label: 'Pupiales - Nariño',
  },
  {
    value: 'narino-ricaurte',
    label: 'Ricaurte - Nariño',
  },
  {
    value: 'narino-robertopayan',
    label: 'Roberto Payán - Nariño',
  },
  {
    value: 'narino-samaniego',
    label: 'Samaniego - Nariño',
  },
  {
    value: 'narino-sanandresdetumaco',
    label: 'San Andrés De Tumaco - Nariño',
  },
  {
    value: 'narino-sanbernardo',
    label: 'San Bernardo - Nariño',
  },
  {
    value: 'narino-sanlorenzo',
    label: 'San Lorenzo - Nariño',
  },
  {
    value: 'narino-sanpablo',
    label: 'San Pablo - Nariño',
  },
  {
    value: 'narino-sanpedrodecartago',
    label: 'San Pedro De Cartago - Nariño',
  },
  {
    value: 'narino-sandona',
    label: 'Sandoná - Nariño',
  },
  {
    value: 'narino-santabarbara',
    label: 'Santa Bárbara - Nariño',
  },
  {
    value: 'narino-santacruz',
    label: 'Santacruz - Nariño',
  },
  {
    value: 'narino-sapuyes',
    label: 'Sapuyes - Nariño',
  },
  {
    value: 'narino-taminango',
    label: 'Taminango - Nariño',
  },
  {
    value: 'narino-tangua',
    label: 'Tangua - Nariño',
  },
  {
    value: 'narino-tuquerres',
    label: 'Túquerres - Nariño',
  },
  {
    value: 'narino-yacuanquer',
    label: 'Yacuanquer - Nariño',
  },
  {
    value: 'nortedesantander',
    label: 'Norte de Santander - Norte de Santander',
  },
  {
    value: 'nortedesantander-arboledas',
    label: 'Arboledas - Norte de Santander',
  },
  {
    value: 'nortedesantander-bochalema',
    label: 'Bochalema - Norte de Santander',
  },
  {
    value: 'nortedesantander-bucarasica',
    label: 'Bucarasica - Norte de Santander',
  },
  {
    value: 'nortedesantander-chinacota',
    label: 'Chinácota - Norte de Santander',
  },
  {
    value: 'nortedesantander-chitaga',
    label: 'Chitagá - Norte de Santander',
  },
  {
    value: 'nortedesantander-convencion',
    label: 'Convención - Norte de Santander',
  },
  {
    value: 'nortedesantander-cucutilla',
    label: 'Cucutilla - Norte de Santander',
  },
  {
    value: 'nortedesantander-cachira',
    label: 'Cáchira - Norte de Santander',
  },
  {
    value: 'nortedesantander-cacota',
    label: 'Cácota - Norte de Santander',
  },
  {
    value: 'nortedesantander-cucuta',
    label: 'Cúcuta - Norte de Santander',
  },
  {
    value: 'nortedesantander-durania',
    label: 'Durania - Norte de Santander',
  },
  {
    value: 'nortedesantander-elcarmen',
    label: 'El Carmen - Norte de Santander',
  },
  {
    value: 'nortedesantander-eltarra',
    label: 'El Tarra - Norte de Santander',
  },
  {
    value: 'nortedesantander-elzulia',
    label: 'El Zulia - Norte de Santander',
  },
  {
    value: 'nortedesantander-gramalote',
    label: 'Gramalote - Norte de Santander',
  },
  {
    value: 'nortedesantander-hacari',
    label: 'Hacarí - Norte de Santander',
  },
  {
    value: 'nortedesantander-herran',
    label: 'Herrán - Norte de Santander',
  },
  {
    value: 'nortedesantander-laesperanza',
    label: 'La Esperanza - Norte de Santander',
  },
  {
    value: 'nortedesantander-laplaya',
    label: 'La Playa - Norte de Santander',
  },
  {
    value: 'nortedesantander-labateca',
    label: 'Labateca - Norte de Santander',
  },
  {
    value: 'nortedesantander-lospatios',
    label: 'Los Patios - Norte de Santander',
  },
  {
    value: 'nortedesantander-lourdes',
    label: 'Lourdes - Norte de Santander',
  },
  {
    value: 'nortedesantander-mutiscua',
    label: 'Mutiscua - Norte de Santander',
  },
  {
    value: 'nortedesantander-ocana',
    label: 'Ocaña - Norte de Santander',
  },
  {
    value: 'nortedesantander-pamplona',
    label: 'Pamplona - Norte de Santander',
  },
  {
    value: 'nortedesantander-pamplonita',
    label: 'Pamplonita - Norte de Santander',
  },
  {
    value: 'nortedesantander-puertosantander',
    label: 'Puerto Santander - Norte de Santander',
  },
  {
    value: 'nortedesantander-ragonvalia',
    label: 'Ragonvalia - Norte de Santander',
  },
  {
    value: 'nortedesantander-salazar',
    label: 'Salazar - Norte de Santander',
  },
  {
    value: 'nortedesantander-sancalixto',
    label: 'San Calixto - Norte de Santander',
  },
  {
    value: 'nortedesantander-sancayetano',
    label: 'San Cayetano - Norte de Santander',
  },
  {
    value: 'nortedesantander-santiago',
    label: 'Santiago - Norte de Santander',
  },
  {
    value: 'nortedesantander-sardinata',
    label: 'Sardinata - Norte de Santander',
  },
  {
    value: 'nortedesantander-silos',
    label: 'Silos - Norte de Santander',
  },
  {
    value: 'nortedesantander-teorama',
    label: 'Teorama - Norte de Santander',
  },
  {
    value: 'nortedesantander-tibu',
    label: 'Tibú - Norte de Santander',
  },
  {
    value: 'nortedesantander-toledo',
    label: 'Toledo - Norte de Santander',
  },
  {
    value: 'nortedesantander-villacaro',
    label: 'Villa Caro - Norte de Santander',
  },
  {
    value: 'nortedesantander-villadelrosario',
    label: 'Villa Del Rosario - Norte de Santander',
  },
  {
    value: 'nortedesantander-abrego',
    label: 'Ábrego - Norte de Santander',
  },
  {
    value: 'quindio',
    label: 'Quindio',
  },
  {
    value: 'quindio-armenia',
    label: 'Armenia - Quindio',
  },
  {
    value: 'quindio-buenavista',
    label: 'Buenavista - Quindio',
  },
  {
    value: 'quindio-calarca',
    label: 'Calarcá - Quindio',
  },
  {
    value: 'quindio-circasia',
    label: 'Circasia - Quindio',
  },
  {
    value: 'quindio-cordoba',
    label: 'Córdoba - Quindio',
  },
  {
    value: 'quindio-filandia',
    label: 'Filandia - Quindio',
  },
  {
    value: 'quindio-genova',
    label: 'Génova - Quindio',
  },
  {
    value: 'quindio-latebaida',
    label: 'La Tebaida - Quindio',
  },
  {
    value: 'quindio-montenegro',
    label: 'Montenegro - Quindio',
  },
  {
    value: 'quindio-pijao',
    label: 'Pijao - Quindio',
  },
  {
    value: 'quindio-quimbaya',
    label: 'Quimbaya - Quindio',
  },
  {
    value: 'quindio-salento',
    label: 'Salento - Quindio',
  },
  {
    value: 'risaralda',
    label: 'Risaralda',
  },
  {
    value: 'risaralda-apia',
    label: 'Apía - Risaralda',
  },
  {
    value: 'risaralda-balboa',
    label: 'Balboa - Risaralda',
  },
  {
    value: 'risaralda-belendeumbria',
    label: 'Belén De Umbría - Risaralda',
  },
  {
    value: 'risaralda-dosquebradas',
    label: 'Dosquebradas - Risaralda',
  },
  {
    value: 'risaralda-guatica',
    label: 'Guática - Risaralda',
  },
  {
    value: 'risaralda-lacelia',
    label: 'La Celia - Risaralda',
  },
  {
    value: 'risaralda-lavirginia',
    label: 'La Virginia - Risaralda',
  },
  {
    value: 'risaralda-marsella',
    label: 'Marsella - Risaralda',
  },
  {
    value: 'risaralda-mistrato',
    label: 'Mistrató - Risaralda',
  },
  {
    value: 'risaralda-pereira',
    label: 'Pereira - Risaralda',
  },
  {
    value: 'risaralda-pueblorico',
    label: 'Pueblo Rico - Risaralda',
  },
  {
    value: 'risaralda-quinchia',
    label: 'Quinchía - Risaralda',
  },
  {
    value: 'risaralda-santarosadecabal',
    label: 'Santa Rosa De Cabal - Risaralda',
  },
  {
    value: 'risaralda-santuario',
    label: 'Santuario - Risaralda',
  },
  {
    value: 'santander',
    label: 'Santander',
  },
  {
    value: 'santander-aguada',
    label: 'Aguada - Santander',
  },
  {
    value: 'santander-albania',
    label: 'Albania - Santander',
  },
  {
    value: 'santander-aratoca',
    label: 'Aratoca - Santander',
  },
  {
    value: 'santander-barbosa',
    label: 'Barbosa - Santander',
  },
  {
    value: 'santander-barichara',
    label: 'Barichara - Santander',
  },
  {
    value: 'santander-barrancabermeja',
    label: 'Barrancabermeja - Santander',
  },
  {
    value: 'santander-betulia',
    label: 'Betulia - Santander',
  },
  {
    value: 'santander-bolivar',
    label: 'Bolívar - Santander',
  },
  {
    value: 'santander-bucaramanga',
    label: 'Bucaramanga - Santander',
  },
  {
    value: 'santander-cabrera',
    label: 'Cabrera - Santander',
  },
  {
    value: 'santander-california',
    label: 'California - Santander',
  },
  {
    value: 'santander-capitanejo',
    label: 'Capitanejo - Santander',
  },
  {
    value: 'santander-carcasi',
    label: 'Carcasí - Santander',
  },
  {
    value: 'santander-cepita',
    label: 'Cepitá - Santander',
  },
  {
    value: 'santander-cerrito',
    label: 'Cerrito - Santander',
  },
  {
    value: 'santander-charala',
    label: 'Charalá - Santander',
  },
  {
    value: 'santander-charta',
    label: 'Charta - Santander',
  },
  {
    value: 'santander-chima',
    label: 'Chima - Santander',
  },
  {
    value: 'santander-chipata',
    label: 'Chipatá - Santander',
  },
  {
    value: 'santander-cimitarra',
    label: 'Cimitarra - Santander',
  },
  {
    value: 'santander-concepcion',
    label: 'Concepción - Santander',
  },
  {
    value: 'santander-confines',
    label: 'Confines - Santander',
  },
  {
    value: 'santander-contratacion',
    label: 'Contratación - Santander',
  },
  {
    value: 'santander-coromoro',
    label: 'Coromoro - Santander',
  },
  {
    value: 'santander-curiti',
    label: 'Curití - Santander',
  },
  {
    value: 'santander-elcarmendechucuri',
    label: 'El Carmen De Chucurí - Santander',
  },
  {
    value: 'santander-elguacamayo',
    label: 'El Guacamayo - Santander',
  },
  {
    value: 'santander-elpenon',
    label: 'El Peñón - Santander',
  },
  {
    value: 'santander-elplayon',
    label: 'El Playón - Santander',
  },
  {
    value: 'santander-encino',
    label: 'Encino - Santander',
  },
  {
    value: 'santander-enciso',
    label: 'Enciso - Santander',
  },
  {
    value: 'santander-floridablanca',
    label: 'Floridablanca - Santander',
  },
  {
    value: 'santander-florian',
    label: 'Florián - Santander',
  },
  {
    value: 'santander-galan',
    label: 'Galán - Santander',
  },
  {
    value: 'santander-giron',
    label: 'Girón - Santander',
  },
  {
    value: 'santander-guaca',
    label: 'Guaca - Santander',
  },
  {
    value: 'santander-guadalupe',
    label: 'Guadalupe - Santander',
  },
  {
    value: 'santander-guapota',
    label: 'Guapotá - Santander',
  },
  {
    value: 'santander-guavata',
    label: 'Guavatá - Santander',
  },
  {
    value: 'santander-gambita',
    label: 'Gámbita - Santander',
  },
  {
    value: 'santander-guepsa',
    label: 'Güepsa - Santander',
  },
  {
    value: 'santander-hato',
    label: 'Hato - Santander',
  },
  {
    value: 'santander-jesusmaria',
    label: 'Jesús María - Santander',
  },
  {
    value: 'santander-jordan',
    label: 'Jordán - Santander',
  },
  {
    value: 'santander-labelleza',
    label: 'La Belleza - Santander',
  },
  {
    value: 'santander-lapaz',
    label: 'La Paz - Santander',
  },
  {
    value: 'santander-landazuri',
    label: 'Landázuri - Santander',
  },
  {
    value: 'santander-lebrija',
    label: 'Lebrija - Santander',
  },
  {
    value: 'santander-lossantos',
    label: 'Los Santos - Santander',
  },
  {
    value: 'santander-macaravita',
    label: 'Macaravita - Santander',
  },
  {
    value: 'santander-matanza',
    label: 'Matanza - Santander',
  },
  {
    value: 'santander-mogotes',
    label: 'Mogotes - Santander',
  },
  {
    value: 'santander-molagavita',
    label: 'Molagavita - Santander',
  },
  {
    value: 'santander-malaga',
    label: 'Málaga - Santander',
  },
  {
    value: 'santander-ocamonte',
    label: 'Ocamonte - Santander',
  },
  {
    value: 'santander-oiba',
    label: 'Oiba - Santander',
  },
  {
    value: 'santander-onzaga',
    label: 'Onzaga - Santander',
  },
  {
    value: 'santander-palmar',
    label: 'Palmar - Santander',
  },
  {
    value: 'santander-palmasdelsocorro',
    label: 'Palmas Del Socorro - Santander',
  },
  {
    value: 'santander-piedecuesta',
    label: 'Piedecuesta - Santander',
  },
  {
    value: 'santander-pinchote',
    label: 'Pinchote - Santander',
  },
  {
    value: 'santander-puentenacional',
    label: 'Puente Nacional - Santander',
  },
  {
    value: 'santander-puertoparra',
    label: 'Puerto Parra - Santander',
  },
  {
    value: 'santander-puertowilches',
    label: 'Puerto Wilches - Santander',
  },
  {
    value: 'santander-paramo',
    label: 'Páramo - Santander',
  },
  {
    value: 'santander-rionegro',
    label: 'Rionegro - Santander',
  },
  {
    value: 'santander-sabanadetorres',
    label: 'Sabana De Torres - Santander',
  },
  {
    value: 'santander-sanandres',
    label: 'San Andrés - Santander',
  },
  {
    value: 'santander-sanbenito',
    label: 'San Benito - Santander',
  },
  {
    value: 'santander-sangil',
    label: 'San Gil - Santander',
  },
  {
    value: 'santander-sanjoaquin',
    label: 'San Joaquín - Santander',
  },
  {
    value: 'santander-sanjosedemiranda',
    label: 'San José De Miranda - Santander',
  },
  {
    value: 'santander-sanmiguel',
    label: 'San Miguel - Santander',
  },
  {
    value: 'santander-sanvicentedechucuri',
    label: 'San Vicente De Chucurí - Santander',
  },
  {
    value: 'santander-santabarbara',
    label: 'Santa Bárbara - Santander',
  },
  {
    value: 'santander-santahelenadelopon',
    label: 'Santa Helena Del Opón - Santander',
  },
  {
    value: 'santander-simacota',
    label: 'Simacota - Santander',
  },
  {
    value: 'santander-socorro',
    label: 'Socorro - Santander',
  },
  {
    value: 'santander-suaita',
    label: 'Suaita - Santander',
  },
  {
    value: 'santander-sucre',
    label: 'Sucre - Santander',
  },
  {
    value: 'santander-surata',
    label: 'Suratá - Santander',
  },
  {
    value: 'santander-tona',
    label: 'Tona - Santander',
  },
  {
    value: 'santander-valledesanjose',
    label: 'Valle De San José - Santander',
  },
  {
    value: 'santander-vetas',
    label: 'Vetas - Santander',
  },
  {
    value: 'santander-villanueva',
    label: 'Villanueva - Santander',
  },
  {
    value: 'santander-velez',
    label: 'Vélez - Santander',
  },
  {
    value: 'santander-zapatoca',
    label: 'Zapatoca - Santander',
  },
  {
    value: 'sucre',
    label: 'Sucre',
  },
  {
    value: 'sucre-buenavista',
    label: 'Buenavista - Sucre',
  },
  {
    value: 'sucre-caimito',
    label: 'Caimito - Sucre',
  },
  {
    value: 'sucre-chalan',
    label: 'Chalán - Sucre',
  },
  {
    value: 'sucre-coloso',
    label: 'Colosó - Sucre',
  },
  {
    value: 'sucre-corozal',
    label: 'Corozal - Sucre',
  },
  {
    value: 'sucre-covenas',
    label: 'Coveñas - Sucre',
  },
  {
    value: 'sucre-elroble',
    label: 'El Roble - Sucre',
  },
  {
    value: 'sucre-galeras',
    label: 'Galeras - Sucre',
  },
  {
    value: 'sucre-guaranda',
    label: 'Guaranda - Sucre',
  },
  {
    value: 'sucre-launion',
    label: 'La Unión - Sucre',
  },
  {
    value: 'sucre-lospalmitos',
    label: 'Los Palmitos - Sucre',
  },
  {
    value: 'sucre-majagual',
    label: 'Majagual - Sucre',
  },
  {
    value: 'sucre-morroa',
    label: 'Morroa - Sucre',
  },
  {
    value: 'sucre-ovejas',
    label: 'Ovejas - Sucre',
  },
  {
    value: 'sucre-palmito',
    label: 'Palmito - Sucre',
  },
  {
    value: 'sucre-sampues',
    label: 'Sampués - Sucre',
  },
  {
    value: 'sucre-sanbenitoabad',
    label: 'San Benito Abad - Sucre',
  },
  {
    value: 'sucre-sanjuandebetulia',
    label: 'San Juan De Betulia - Sucre',
  },
  {
    value: 'sucre-sanluisdesince',
    label: 'San Luis De Sincé - Sucre',
  },
  {
    value: 'sucre-sanmarcos',
    label: 'San Marcos - Sucre',
  },
  {
    value: 'sucre-sanonofre',
    label: 'San Onofre - Sucre',
  },
  {
    value: 'sucre-sanpedro',
    label: 'San Pedro - Sucre',
  },
  {
    value: 'sucre-santiagodetolu',
    label: 'Santiago De Tolú - Sucre',
  },
  {
    value: 'sucre-sincelejo',
    label: 'Sincelejo - Sucre',
  },
  {
    value: 'sucre-sucre',
    label: 'Sucre - Sucre',
  },
  {
    value: 'sucre-toluviejo',
    label: 'Tolú Viejo - Sucre',
  },
  {
    value: 'tolima',
    label: 'Tolima - Tolima',
  },
  {
    value: 'tolima-alpujarra',
    label: 'Alpujarra - Tolima',
  },
  {
    value: 'tolima-alvarado',
    label: 'Alvarado - Tolima',
  },
  {
    value: 'tolima-ambalema',
    label: 'Ambalema - Tolima',
  },
  {
    value: 'tolima-anzoategui',
    label: 'Anzoátegui - Tolima',
  },
  {
    value: 'tolima-armeroguayabal',
    label: 'Armero Guayabal - Tolima',
  },
  {
    value: 'tolima-ataco',
    label: 'Ataco - Tolima',
  },
  {
    value: 'tolima-cajamarca',
    label: 'Cajamarca - Tolima',
  },
  {
    value: 'tolima-carmendeapicala',
    label: 'Carmen De Apicalá - Tolima',
  },
  {
    value: 'tolima-casabianca',
    label: 'Casabianca - Tolima',
  },
  {
    value: 'tolima-chaparral',
    label: 'Chaparral - Tolima',
  },
  {
    value: 'tolima-coello',
    label: 'Coello - Tolima',
  },
  {
    value: 'tolima-coyaima',
    label: 'Coyaima - Tolima',
  },
  {
    value: 'tolima-cunday',
    label: 'Cunday - Tolima',
  },
  {
    value: 'tolima-dolores',
    label: 'Dolores - Tolima',
  },
  {
    value: 'tolima-espinal',
    label: 'Espinal - Tolima',
  },
  {
    value: 'tolima-falan',
    label: 'Falan - Tolima',
  },
  {
    value: 'tolima-flandes',
    label: 'Flandes - Tolima',
  },
  {
    value: 'tolima-fresno',
    label: 'Fresno - Tolima',
  },
  {
    value: 'tolima-guamo',
    label: 'Guamo - Tolima',
  },
  {
    value: 'tolima-herveo',
    label: 'Herveo - Tolima',
  },
  {
    value: 'tolima-honda',
    label: 'Honda - Tolima',
  },
  {
    value: 'tolima-ibague',
    label: 'Ibagué - Tolima',
  },
  {
    value: 'tolima-icononzo',
    label: 'Icononzo - Tolima',
  },
  {
    value: 'tolima-lerida',
    label: 'Lérida - Tolima',
  },
  {
    value: 'tolima-libano',
    label: 'Líbano - Tolima',
  },
  {
    value: 'tolima-melgar',
    label: 'Melgar - Tolima',
  },
  {
    value: 'tolima-murillo',
    label: 'Murillo - Tolima',
  },
  {
    value: 'tolima-natagaima',
    label: 'Natagaima - Tolima',
  },
  {
    value: 'tolima-ortega',
    label: 'Ortega - Tolima',
  },
  {
    value: 'tolima-palocabildo',
    label: 'Palocabildo - Tolima',
  },
  {
    value: 'tolima-piedras',
    label: 'Piedras - Tolima',
  },
  {
    value: 'tolima-planadas',
    label: 'Planadas - Tolima',
  },
  {
    value: 'tolima-prado',
    label: 'Prado - Tolima',
  },
  {
    value: 'tolima-purificacion',
    label: 'Purificación - Tolima',
  },
  {
    value: 'tolima-rioblanco',
    label: 'Rioblanco - Tolima',
  },
  {
    value: 'tolima-roncesvalles',
    label: 'Roncesvalles - Tolima',
  },
  {
    value: 'tolima-rovira',
    label: 'Rovira - Tolima',
  },
  {
    value: 'tolima-saldana',
    label: 'Saldaña - Tolima',
  },
  {
    value: 'tolima-sanantonio',
    label: 'San Antonio - Tolima',
  },
  {
    value: 'tolima-sanluis',
    label: 'San Luis - Tolima',
  },
  {
    value: 'tolima-sansebastiandemariquita',
    label: 'San Sebastián De Mariquita - Tolima',
  },
  {
    value: 'tolima-santaisabel',
    label: 'Santa Isabel - Tolima',
  },
  {
    value: 'tolima-suarez',
    label: 'Suárez - Tolima',
  },
  {
    value: 'tolima-valledesanjuan',
    label: 'Valle De San Juan - Tolima',
  },
  {
    value: 'tolima-venadillo',
    label: 'Venadillo - Tolima',
  },
  {
    value: 'tolima-villahermosa',
    label: 'Villahermosa - Tolima',
  },
  {
    value: 'tolima-villarrica',
    label: 'Villarrica - Tolima',
  },
  {
    value: 'valledelcauca',
    label: 'Valle del Cauca',
  },
  {
    value: 'valledelcauca-alcala',
    label: 'Alcalá - Valle del Cauca',
  },
  {
    value: 'valledelcauca-andalucia',
    label: 'Andalucía - Valle del Cauca',
  },
  {
    value: 'valledelcauca-ansermanuevo',
    label: 'Ansermanuevo - Valle del Cauca',
  },
  {
    value: 'valledelcauca-argelia',
    label: 'Argelia - Valle del Cauca',
  },
  {
    value: 'valledelcauca-bolivar',
    label: 'Bolívar - Valle del Cauca',
  },
  {
    value: 'valledelcauca-buenaventura',
    label: 'Buenaventura - Valle del Cauca',
  },
  {
    value: 'valledelcauca-bugalagrande',
    label: 'Bugalagrande - Valle del Cauca',
  },
  {
    value: 'valledelcauca-caicedonia',
    label: 'Caicedonia - Valle del Cauca',
  },
  {
    value: 'valledelcauca-cali',
    label: 'Cali - Valle del Cauca',
  },
  {
    value: 'valledelcauca-calima',
    label: 'Calima - Valle del Cauca',
  },
  {
    value: 'valledelcauca-candelaria',
    label: 'Candelaria - Valle del Cauca',
  },
  {
    value: 'valledelcauca-cartago',
    label: 'Cartago - Valle del Cauca',
  },
  {
    value: 'valledelcauca-dagua',
    label: 'Dagua - Valle del Cauca',
  },
  {
    value: 'valledelcauca-elcairo',
    label: 'El Cairo - Valle del Cauca',
  },
  {
    value: 'valledelcauca-elcerrito',
    label: 'El Cerrito - Valle del Cauca',
  },
  {
    value: 'valledelcauca-eldovio',
    label: 'El Dovio - Valle del Cauca',
  },
  {
    value: 'valledelcauca-elaguila',
    label: 'El Águila - Valle del Cauca',
  },
  {
    value: 'valledelcauca-florida',
    label: 'Florida - Valle del Cauca',
  },
  {
    value: 'valledelcauca-ginebra',
    label: 'Ginebra - Valle del Cauca',
  },
  {
    value: 'valledelcauca-guacari',
    label: 'Guacarí - Valle del Cauca',
  },
  {
    value: 'valledelcauca-guadalajaradebuga',
    label: 'Guadalajara De Buga - Valle del Cauca',
  },
  {
    value: 'valledelcauca-jamundi',
    label: 'Jamundí - Valle del Cauca',
  },
  {
    value: 'valledelcauca-lacumbre',
    label: 'La Cumbre - Valle del Cauca',
  },
  {
    value: 'valledelcauca-launion',
    label: 'La Unión - Valle del Cauca',
  },
  {
    value: 'valledelcauca-lavictoria',
    label: 'La Victoria - Valle del Cauca',
  },
  {
    value: 'valledelcauca-obando',
    label: 'Obando - Valle del Cauca',
  },
  {
    value: 'valledelcauca-palmira',
    label: 'Palmira - Valle del Cauca',
  },
  {
    value: 'valledelcauca-pradera',
    label: 'Pradera - Valle del Cauca',
  },
  {
    value: 'valledelcauca-restrepo',
    label: 'Restrepo - Valle del Cauca',
  },
  {
    value: 'valledelcauca-riofrio',
    label: 'Riofrío - Valle del Cauca',
  },
  {
    value: 'valledelcauca-roldanillo',
    label: 'Roldanillo - Valle del Cauca',
  },
  {
    value: 'valledelcauca-sanpedro',
    label: 'San Pedro - Valle del Cauca',
  },
  {
    value: 'valledelcauca-sevilla',
    label: 'Sevilla - Valle del Cauca',
  },
  {
    value: 'valledelcauca-toro',
    label: 'Toro - Valle del Cauca',
  },
  {
    value: 'valledelcauca-trujillo',
    label: 'Trujillo - Valle del Cauca',
  },
  {
    value: 'valledelcauca-tulua',
    label: 'Tuluá - Valle del Cauca',
  },
  {
    value: 'valledelcauca-ulloa',
    label: 'Ulloa - Valle del Cauca',
  },
  {
    value: 'valledelcauca-versalles',
    label: 'Versalles - Valle del Cauca',
  },
  {
    value: 'valledelcauca-vijes',
    label: 'Vijes - Valle del Cauca',
  },
  {
    value: 'valledelcauca-yotoco',
    label: 'Yotoco - Valle del Cauca',
  },
  {
    value: 'valledelcauca-yumbo',
    label: 'Yumbo - Valle del Cauca',
  },
  {
    value: 'valledelcauca-zarzal',
    label: 'Zarzal - Valle del Cauca',
  },
  {
    value: 'arauca-arauca',
    label: 'Arauca',
  },
  {
    value: 'arauca-arauquita',
    label: 'Arauquita - Arauca',
  },
  {
    value: 'arauca-cravonorte',
    label: 'Cravo Norte - Arauca',
  },
  {
    value: 'arauca-fortul',
    label: 'Fortul - Arauca',
  },
  {
    value: 'arauca-puertorondon',
    label: 'Puerto Rondón - Arauca',
  },
  {
    value: 'arauca-saravena',
    label: 'Saravena - Arauca',
  },
  {
    value: 'arauca-tame',
    label: 'Tame - Arauca',
  },
  {
    value: 'casanare',
    label: 'Casanare',
  },
  {
    value: 'casanare-aguazul',
    label: 'Aguazul - Casanare',
  },
  {
    value: 'casanare-chameza',
    label: 'Chámeza - Casanare',
  },
  {
    value: 'casanare-hatocorozal',
    label: 'Hato Corozal - Casanare',
  },
  {
    value: 'casanare-lasalina',
    label: 'La Salina - Casanare',
  },
  {
    value: 'casanare-mani',
    label: 'Maní - Casanare',
  },
  {
    value: 'casanare-monterrey',
    label: 'Monterrey - Casanare',
  },
  {
    value: 'casanare-nunchia',
    label: 'Nunchía - Casanare',
  },
  {
    value: 'casanare-orocue',
    label: 'Orocué - Casanare',
  },
  {
    value: 'casanare-pazdeariporo',
    label: 'Paz De Ariporo - Casanare',
  },
  {
    value: 'casanare-pore',
    label: 'Pore - Casanare',
  },
  {
    value: 'casanare-recetor',
    label: 'Recetor - Casanare',
  },
  {
    value: 'casanare-sabanalarga',
    label: 'Sabanalarga - Casanare',
  },
  {
    value: 'casanare-sanluisdepalenque',
    label: 'San Luis De Palenque - Casanare',
  },
  {
    value: 'casanare-sacama',
    label: 'Sácama - Casanare',
  },
  {
    value: 'casanare-tauramena',
    label: 'Tauramena - Casanare',
  },
  {
    value: 'casanare-trinidad',
    label: 'Trinidad - Casanare',
  },
  {
    value: 'casanare-tamara',
    label: 'Támara - Casanare',
  },
  {
    value: 'casanare-villanueva',
    label: 'Villanueva - Casanare',
  },
  {
    value: 'casanare-yopal',
    label: 'Yopal - Casanare',
  },
  {
    value: 'putumayo',
    label: 'Putumayo - Putumayo',
  },
  {
    value: 'putumayo-colon',
    label: 'Colón - Putumayo',
  },
  {
    value: 'putumayo-mocoa',
    label: 'Mocoa - Putumayo',
  },
  {
    value: 'putumayo-orito',
    label: 'Orito - Putumayo',
  },
  {
    value: 'putumayo-puertoasis',
    label: 'Puerto Asís - Putumayo',
  },
  {
    value: 'putumayo-puertocaicedo',
    label: 'Puerto Caicedo - Putumayo',
  },
  {
    value: 'putumayo-puertoguzman',
    label: 'Puerto Guzmán - Putumayo',
  },
  {
    value: 'putumayo-puertoleguizamo',
    label: 'Puerto Leguízamo - Putumayo',
  },
  {
    value: 'putumayo-sanfrancisco',
    label: 'San Francisco - Putumayo',
  },
  {
    value: 'putumayo-sanmiguel',
    label: 'San Miguel - Putumayo',
  },
  {
    value: 'putumayo-santiago',
    label: 'Santiago - Putumayo',
  },
  {
    value: 'putumayo-sibundoy',
    label: 'Sibundoy - Putumayo',
  },
  {
    value: 'putumayo-valledelguamuez',
    label: 'Valle Del Guamuez - Putumayo',
  },
  {
    value: 'putumayo-villagarzon',
    label: 'Villagarzón - Putumayo',
  },
  {
    value: 'sanandres',
    label: 'San Andrés',
  },
  {
    value: 'sanandres-providencia',
    label: 'Providencia - San Andrés',
  },
  {
    value: 'sanandres-sanandres',
    label: 'San Andrés - San Andrés',
  },
  {
    value: 'amazonas',
    label: 'Amazonas',
  },
  {
    value: 'amazonas-elencanto',
    label: 'El Encanto - Amazonas',
  },
  {
    value: 'amazonas-lachorrera',
    label: 'La Chorrera - Amazonas',
  },
  {
    value: 'amazonas-lapedrera',
    label: 'La Pedrera - Amazonas',
  },
  {
    value: 'amazonas-lavictoria',
    label: 'La Victoria - Amazonas',
  },
  {
    value: 'amazonas-leticia',
    label: 'Leticia - Amazonas',
  },
  {
    value: 'amazonas-miritiparana',
    label: 'Mirití - Paraná - Amazonas',
  },
  {
    value: 'amazonas-puertoalegria',
    label: 'Puerto Alegría - Amazonas',
  },
  {
    value: 'amazonas-puertoarica',
    label: 'Puerto Arica - Amazonas',
  },
  {
    value: 'amazonas-puertonarino',
    label: 'Puerto Nariño - Amazonas',
  },
  {
    value: 'amazonas-puertosantander',
    label: 'Puerto Santander - Amazonas',
  },
  {
    value: 'amazonas-tarapaca',
    label: 'Tarapacá - Amazonas',
  },
  {
    value: 'guainia',
    label: 'Guainía',
  },
  {
    value: 'guainia-barrancominas',
    label: 'Barranco Minas',
  },
  {
    value: 'guainia-cacahual',
    label: 'Cacahual',
  },
  {
    value: 'guainia-inirida',
    label: 'Inírida',
  },
  {
    value: 'guainia-laguadalupe',
    label: 'La Guadalupe',
  },
  {
    value: 'guainia-mapiripana',
    label: 'Mapiripana',
  },
  {
    value: 'guainia-morichal',
    label: 'Morichal',
  },
  {
    value: 'guainia-panapana',
    label: 'Pana Pana',
  },
  {
    value: 'guainia-puertocolombia',
    label: 'Puerto Colombia',
  },
  {
    value: 'guainia-sanfelipe',
    label: 'San Felipe',
  },
  {
    value: 'guaviare',
    label: 'Guaviare',
  },
  {
    value: 'guaviare-calamar',
    label: 'Calamar',
  },
  {
    value: 'guaviare-elretorno',
    label: 'El Retorno',
  },
  {
    value: 'guaviare-miraflores',
    label: 'Miraflores',
  },
  {
    value: 'guaviare-sanjosedelguaviare',
    label: 'San José Del Guaviare',
  },
  {
    value: 'vaupes',
    label: 'Vaupés',
  },
  {
    value: 'vaupes-caruru',
    label: 'Carurú',
  },
  {
    value: 'vaupes-mitu',
    label: 'Mitú',
  },
  {
    value: 'vaupes-pacoa',
    label: 'Pacoa',
  },
  {
    value: 'vaupes-papunaua',
    label: 'Papunaua',
  },
  {
    value: 'vaupes-taraira',
    label: 'Taraira',
  },
  {
    value: 'vaupes-yavarate',
    label: 'Yavaraté',
  },
  {
    value: 'vichada',
    label: 'Vichada',
  },
  {
    value: 'vichada-cumaribo',
    label: 'Cumaribo',
  },
  {
    value: 'vichada-laprimavera',
    label: 'La Primavera',
  },
  {
    value: 'vichada-puertocarreno',
    label: 'Puerto Carreño',
  },
  {
    value: 'vichada-santarosalia',
    label: 'Santa Rosalía',
  },
];
