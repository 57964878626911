import * as React from 'react';
import DataTable from 'react-data-table-component';
import { Button, TextField, Modal, Backdrop, Snackbar } from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import matchSorter from 'match-sorter';
import { TEventTarget } from 'types';
import { useStyles } from './styles';
import Fade from 'components/Admin/AdminUserControlFadeAnimated/AdminUserControlFadeAnimated';
import SignNewUser from 'pages/Admin/SignNewUser/SignNewUser';
import UpdateUser from 'pages/Admin/UpdateUser/UpdateUser';
import { listAllUsers } from 'services/AdminUsersControlService';
import { formatDate } from 'utils/utils';
import MuiAlert from '@material-ui/lab/Alert';

const UserControl = () => {
  const classes = useStyles();

  const [filteredOpportunities, setFilteredOpportunities] = React.useState<string>('');
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openModalForUpdateUser, setOpenModalForUpdateUser] = React.useState<boolean>(false);
  const [userCollection, setUserCollection] = React.useState<ICognitoUsers[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openSnack, setOpenSnack] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  // filter items according the data
  const filteredItems =
    userCollection !== null
      ? matchSorter(userCollection, filteredOpportunities, {
          keys: ['Attributes.3.Value', 'Attributes.5.Value'],
        })
      : [];

  // on Error
  const onError = (error: string) => {
    setOpenSnack(true);
    setErrorMessage(error);
  };

  // launch snackBar
  const onCloseSnackBar = () => {
    setOpenSnack(false);
  };

  // handler filter data table
  const onFilterSearch = (e: TEventTarget) => setFilteredOpportunities(e.target.value);

  const toggleModal = () => setOpenModal(!openModal);
  const toggleModalForUpdateUser = () => setOpenModalForUpdateUser(!openModalForUpdateUser);

  const columns = [
    {
      name: 'Nombre',
      selector: (row: ICognitoUsers) => row.Attributes[3].Value,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: ICognitoUsers) => row.Attributes[5].Value,
      sortable: true,
    },
    {
      name: 'Fecha',
      selector: (row: ICognitoUsers) => formatDate(row.UserCreateDate),
      sortable: true,
    },
    {
      name: 'Estado usuario',
      cell: (row: ICognitoUsers) =>
        row.Enabled ? (
          <Button variant="contained" size="small" color="primary">
            Desactivar
          </Button>
        ) : (
          <Button variant="contained" size="small" color="primary">
            Activar
          </Button>
        ),
    },
    {
      name: 'Editar usuario',
      cell: (row: ICognitoUsers) => (
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={toggleModalForUpdateUser}>
          <Edit style={{ color: 'white' }} />
        </Button>
      ),
    },
  ];

  // Document title
  React.useEffect(() => {
    document.title = 'Control de usuarios';

    setLoading(true);
    listAllUsers(onError).then((response) => {
      setUserCollection(response.users.users);
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <DataTable
        title="Control de usuarios"
        columns={columns}
        progressPending={loading}
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por página:',
          rangeSeparatorText: 'de',
        }}
        noDataComponent="No se han encontrado registros"
        data={filteredItems}
        defaultSortField="director"
        pagination={true}
        highlightOnHover
        subHeader={true}
        subHeaderComponent={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              id="outlined-basic"
              label="Buscar"
              variant="outlined"
              size="small"
              style={{ margin: '5px' }}
              onChange={onFilterSearch}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={toggleModal}>
              <Add />
            </Button>
          </div>
        }
        fixedHeaderScrollHeight="500px"
      />
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={toggleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModal}>
          <div className={classes.paper}>
            <SignNewUser toggleModal={toggleModal} />
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={openModalForUpdateUser}
        onClose={toggleModalForUpdateUser}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModalForUpdateUser}>
          <div className={classes.paper}>
            <UpdateUser toggleModal={toggleModalForUpdateUser} />
          </div>
        </Fade>
      </Modal>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={onCloseSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={onCloseSnackBar} severity="error">
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};

export default UserControl;
