import React from 'react';
import Avatar from '@material-ui/core/Avatar';

import { StyledBadge, useStyles } from './styles';
import AvatarImage from 'assets/Avatar/avatar_hombre-3.svg';

export default function BadgeAvatars() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <StyledBadge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot">
        <Avatar alt="Remy Sharp" src={AvatarImage} />
      </StyledBadge>
    </div>
  );
}
