import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

import AuthImage from 'assets/Auth/auth.svg';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
  },
  form: {},
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundImage: `url(${AuthImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'left',
  },
}));
