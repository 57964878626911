import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      marginBottom: 10,
    },
    margin: {
      width: '100%',
    },
  })
);
