import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 10,
    },
    cardMedia: (props: IStyledCardDisabled) => ({
      paddingTop: '56.25%', // 16:9
      borderRadius: 10,
      margin: 8,
      opacity: props.disabled ? 0.2 : 1,
    }),
    cardContent: {
      flexGrow: 1,
    },
    cardTitle: {
      fontWeight: 'bold',
    },
  })
);
