import { HttpRequest } from './HttpRequest';
import { baseUrl, userControl, quotationUrl } from 'utils/ApiRoutes';

/**
 * List all users
 */
export const listAllUsers = async (
  onError: (text: string) => void
): Promise<IListAllUsersResponse> => {
  try {
    const users = await new HttpRequest().Get<IListAllUsersResponse>(
      `${baseUrl}${userControl.cognitoUsers}`
    );

    return users.data;
  } catch (err) {
    throw onError('Ha ocurrido un error al listar los usuarios.');
  }
};

/**
 * List all quotations
 */
export const listAllCotizaciones = async (
  onError: (text: string) => void
): Promise<IUserCotizaciones[]> => {
  try {
    const users = await new HttpRequest().Get<IUserCotizaciones[]>(
      `${baseUrl}${userControl.cotizacionesUsers}`
    );

    return users.data;
  } catch (err) {
    throw onError('Ha ocurrido un error al listar las cotizaciones.');
  }
};



/**
 * Get the resume of quotation
 */
export const getQuotationResume = async (
  typeQuoteWithId: string
): Promise<IQuotationResumeService> => {
  try {
    const resume = await new HttpRequest().Get<IQuotationResumeService>(
      `${baseUrl}${quotationUrl.quoteResume}${typeQuoteWithId}`
    );
    return resume.data;
  } catch (err) {
    throw new Error(err);
  }
};
