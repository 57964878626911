import React from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// styles
import useStyles from './styles';

// logo
import LogoCavca from 'assets/Branding/logo_cavca_blanco.png';
import ErrorLogo from 'assets/Error/error-404.svg';

export default function Error() {
  const classes = useStyles();

  return (
    <Grid container={true} className={classes.container}>
      <div className={classes.logotype}>
        <img className={classes.logotypeIcon} src={LogoCavca} alt="logo" />
        <Typography variant="h3" color="primary" className={classes.logotypeText}>
          Cavca Admin
        </Typography>
      </div>
      <Paper classes={{ root: classes.paperRoot }}>
        {/* <Typography
          variant="h1"
          color="primary"
          className={classnames(classes.textRow, classes.errorCode)}>
          404
        </Typography> */}
        <img className={classes.errorLogo} src={ErrorLogo} alt="404" />
        <Typography variant="h5" color="primary" className={classes.textRow}>
          Oops. Parece que la página que estás buscando no existe
        </Typography>
        <Typography
          variant="h6"
          color="secondary"
          className={classnames(classes.textRow, classes.safetyText)}>
          Pero estamos aquí para llevarte de vuelta
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/"
          size="large"
          className={classes.backButton}>
          Volver al inicio
        </Button>
      </Paper>
    </Grid>
  );
}
