import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0077c8',
      light: '#00a9e0',
      dark: '#003da5',
    },
    secondary: {
      main: '#64a70b',
      light: '#97d700',
      dark: '#658d1b',
    },
    error: {
      main: '#f9423a',
      light: '#fdc6c4',
      dark: '#a50034',
    },
    warning: {
      main: '#f2a900',
      light: '#ffcd00',
      dark: '#cc8a00',
    },
    info: {
      main: '#009ca6',
      light: '#00aec7',
      dark: '#00677f',
    },
    success: {
      main: '#009a44',
      light: '#00b74f',
      dark: '#007a53',
      contrastText: '#e4f3c3',
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;
